import { BASE_URL } from "../../config";

export const HOME_PAGE_URL = BASE_URL
const TAB_POSITION = 1;

const MANAGER = 'attraction';
const MANAGER_PRIMARY = 'attractionprimary';

export const homeTabs = [
  {
    name: 'home.profile_tab',
    showForRoles: [MANAGER, MANAGER_PRIMARY],
    path: '',
    matchUrls: [''],
  },
  {
    name: 'home.attractions_tab',
    showForRoles: [MANAGER, MANAGER_PRIMARY],
    path: 'atractions',
    matchUrls: ['atractions'],
  },
  {
    name: 'home.users_tab',
    showForRoles: [MANAGER_PRIMARY],
    path: 'company-users',
    matchUrls: ['company-users'],
  },
  {
    name: 'home.agreement_tab',
    showForRoles: [MANAGER_PRIMARY],
    path: 'agreement',
    matchUrls: ['agreement'],
  }
];

export const getOpenedTabIdByUrlParameter = (tabs, path) => {
  const splittedPath = path.split('/');

  const tabName = splittedPath[TAB_POSITION];

  if (!tabName) return 0;

  const tabId = tabs.findIndex((tab) => tab.matchUrls.includes(tabName));

  if (tabId === -1) return 0;

  return tabId;
};
