import { Controller, useFormContext } from 'react-hook-form';

import { FormControl, MenuItem, Typography } from '@mui/material';

import { TYPE_REGION } from '../../constants';
import { ACTIVE_STATUS } from '../constants';
import { SelectHelperText, SetupFormSelectFieldInput } from '../styled';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

const createRegionOption = (optionsData, selectedLanguage) => {
  if (!optionsData) return null;

  return optionsData.locations.map(
    (region) =>
      region.locationType === TYPE_REGION &&
      region.locationStatus === ACTIVE_STATUS && (
        <MenuItem value={region.id} key={region.id}>
          <Typography variant="inherit" noWrap>
            {region.title[selectedLanguage] ||
              region.title.en ||
              region.title[optionsData.languages[0]]}
          </Typography>
        </MenuItem>
      )
  );
};
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 300,
      width: 250,
    },
  },
};
const SetupFormRegionSelectField = ({
  disabled,
  optionsData,
  selectedLanguage,
  name,
  emptyOption,
}) => {
  const { control, setValue } = useFormContext();
  const { t } = useTranslation();

  const required = useCallback(value => (value ? undefined : t('required')), [selectedLanguage]);

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        validate: required
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl error={!!error} fullWidth>
          <SetupFormSelectFieldInput
            MenuProps={MenuProps}
            value={value}
            disabled={disabled}
            onChange={(event) => {
              onChange(event.target.value);
              setValue('areaId', '');
            }}
            size="small"
          >
            {emptyOption && (
              <MenuItem value={emptyOption.value} id={emptyOption.id}>
                <Typography variant="inherit" noWrap>
                  {emptyOption.label}
                </Typography>
              </MenuItem>
            )}
            {createRegionOption(optionsData, selectedLanguage)}
          </SetupFormSelectFieldInput>
          <SelectHelperText>{error?.message}</SelectHelperText>
        </FormControl>
      )}
    />
  );
};

export default SetupFormRegionSelectField;
