import {
  Autocomplete,
  FormControl,
  FormHelperText,
  Chip,
  Box,
  TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const useStyles = makeStyles({
  textField: {
    '& .MuiOutlinedInput-root': {
      maxHeight: '100px',
      overflowY: 'scroll',
      '& .MuiInputBase-root': {
        maxHeight: '100px',
      },
    },
  },
});

const HelperText = ({ touched, error }) => {
  if (!(touched && error)) {
    return;
  } else {
    return (
      <FormHelperText sx={{ color: '#d43131' }}>
        * {touched && error}
      </FormHelperText>
    );
  }
};

export default function AutocompleteInput({
  input,
  getOptionLabel,
  options,
  meta: { touched, error },
  ...props
}) {
  return (
    <FormControl error={touched && error} fullWidth>
      <Autocomplete
        id="combo-box-demo"
        options={options}
        value={
          props.multiple
            ? input.value || []
            : props.disabled
            ? props.defaultValue
            : input.value || null
        }
        onChange={(_, newValue) => {
          input.onChange(newValue);
        }}
        defaultValue={props.multiple ? [] : null}
        getOptionLabel={getOptionLabel}
        disableCloseOnSelect
        renderInput={(params) => (
          <TextField
            {...params}
            label={props.label}
            size="small"
            required={props.required}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
            }}
            error={touched && error}
            helperText={touched && error}
          />
        )}
        renderTags={(value, getTagProps) => {
          return (
            <Box>
              {value.map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  key={index}
                  label={getOptionLabel(option)}
                />
              ))}
            </Box>
          );
        }}
        {...props}
      />
    </FormControl>
  );
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const ReduxAutocomplete = ({
  input,
  getOptionLabel,
  options,
  getOptionLabelForOptionList,
  meta: { touched, error },
  ...props
}) => {
  return (
    <FormControl error={touched && error} fullWidth>
      <Autocomplete
        id="combo-box-demo"
        options={options}
        value={
          props.multiple ? (input.value.length ? input.value : []) : input.value
        }
        onChange={(_, newValue) => {
          input.onChange(newValue);
        }}
        getOptionLabel={getOptionLabel}
        disableCloseOnSelect
        renderOption={(props, option, { selected }) => {
          return (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {getOptionLabelForOptionList
                ? getOptionLabelForOptionList(option)
                : getOptionLabel(option)}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={props.label}
            size="small"
            required={props.required}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
            }}
            error={touched && error}
            helperText={touched && error}
          />
        )}
        renderTags={(value, getTagProps) => {
          return (
            <Box>
              {value.map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  key={index}
                  label={getOptionLabel(option)}
                />
              ))}
            </Box>
          );
        }}
        {...props}
      />
    </FormControl>
  );
};
