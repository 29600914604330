import {
  SET_VISITS_LIST,
  IS_VISITS_LOADING,
  IS_ITEM_UPDATING,
  SET_VISITS_PAGINATION_SETTINGS,
} from './visitsActions';

const initialState = {
  visitsList: [],
  isDataLoading: false,
  paginationSettings: {
    totalCount: 0,
    totalPages: 0,
    pageNumber: 0,
    pageSize: 1000,
  },
  isItemUpdating: false,
};

const visitsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_VISITS_LIST:
      return {
        ...state,
        visitsList: [...action.payload.data],
      };
    case IS_VISITS_LOADING:
      return {
        ...state,
        isDataLoading: action.isLoading,
      };
    case IS_ITEM_UPDATING:
      return {
        ...state,
        isItemUpdating: action.isUpdating,
      };
    case SET_VISITS_PAGINATION_SETTINGS: {
      return {
        ...state,
        paginationSettings: {
          ...action.payload.data,
        },
      };
    }
    default:
      return state;
  }
};

export default visitsReducer;
