import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import NotificationCheckboxField from '../formFields/NotificationCheckboxField';
import NotificationDateField from '../formFields/NotificationDateField';
import NotificationLanguageTabFields from '../formFields/NotificationLanguageTabFields';
import NotificationMultiselectField from '../formFields/NotificationMultiselectField';
import NotificationStatusField from '../formFields/NotificationStatusField';
import {
  NewNotificationFormControlPanel,
  NotificationEditFormControlPanel,
  NotificationFormFieldTitle,
  NotificationFormFieldWrapper,
} from '../styled';

import {
  FormButton,
  SaveButton,
} from '../../../../components/buttons/FormButtons';
import { useFormContext } from 'react-hook-form';

const EditNewNotificationForm = ({
  selectedLanguage,
  availableAttractions,
  isGeneralNotificationValue,
  languages,
  handleChangeLanguageTab,
  currentTabId,
  statusOptions,
  isFormEdit,
  handleFormEdit,
  handleCancelChanges,
  startDateFieldValue,
  endDateFieldValue,
  showSaveAnimation,
  onSubmit,
  onDeleteNotification,
}) => {
  const { t } = useTranslation();

  const { handleSubmit } = useFormContext();

  return (
    <Box width="100%" maxWidth="1456px">
      <form>
        <Box display="flex" flexDirection="column">
          <NotificationEditFormControlPanel>
            <Box display="flex" gap="10px">
              <FormButton
                label={t('cancel')}
                onClick={handleCancelChanges}
                disabled={!isFormEdit}
              />
              <FormButton
                label={t('edit')}
                onClick={handleFormEdit}
                disabled={isFormEdit}
              />
              <FormButton
                label={t('delete')}
                onClick={onDeleteNotification}
                disabled={showSaveAnimation}
              />
              <SaveButton
                label={t('attractions.deals.buttons.save')}
                onClick={handleSubmit(onSubmit)}
                isLoading={showSaveAnimation}
                disabled={!isFormEdit}
              />
            </Box>
          </NotificationEditFormControlPanel>

          <Box display="flex">
            <NotificationLanguageTabFields
              languages={languages}
              handleChangeLanguageTab={handleChangeLanguageTab}
              currentTabId={currentTabId}
              disabled={!isFormEdit}
            />
          </Box>

          <NotificationFormFieldWrapper>
            <NotificationFormFieldTitle>
              {t('notifications.status')}:
            </NotificationFormFieldTitle>
            <NotificationStatusField
              name="status"
              selectedLanguage={selectedLanguage}
              options={statusOptions}
              disabled={!isFormEdit}
            />
          </NotificationFormFieldWrapper>

          <NotificationFormFieldWrapper>
            <NotificationFormFieldTitle>
              {t('notifications.start_date')}:
            </NotificationFormFieldTitle>
            <NotificationDateField
              name="startDate"
              placeholder={t('notifications.start_date')}
              maxDate={endDateFieldValue}
              required
              disabled={!isFormEdit}
            />
          </NotificationFormFieldWrapper>

          <NotificationFormFieldWrapper>
            <NotificationFormFieldTitle>
              {t('notifications.end_date')}:
            </NotificationFormFieldTitle>
            <NotificationDateField
              name="endDate"
              placeholder={t('notifications.end_date')}
              minDate={startDateFieldValue}
              required
              disabled={!isFormEdit}
            />
          </NotificationFormFieldWrapper>

          <NotificationFormFieldWrapper>
            <NotificationCheckboxField
              name="isGeneralNotification"
              disabled={!isFormEdit}
            />
          </NotificationFormFieldWrapper>

          {!isGeneralNotificationValue && (
            <NotificationFormFieldWrapper>
              <NotificationCheckboxField
                name="isVisibleForGeneralSection"
                disabled={!isFormEdit}
                label={t('notifications.visible_for_general_section')}
              />
            </NotificationFormFieldWrapper>
          )}

          <NotificationFormFieldWrapper>
            <NotificationFormFieldTitle>
              {t('notifications.attraction')}:
            </NotificationFormFieldTitle>
            <NotificationMultiselectField
              name="attractions"
              optionsData={availableAttractions}
              selectedLanguage={selectedLanguage}
              disabled={!isFormEdit || isGeneralNotificationValue}
              required={!isGeneralNotificationValue}
            />
          </NotificationFormFieldWrapper>
        </Box>
      </form>
    </Box>
  );
};

export default EditNewNotificationForm;
