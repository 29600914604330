import { reset, stopSubmit } from 'redux-form';

import {
  addUser,
  getUsersList,
  editUser,
  deleteUser,
  changeUserPassword,
  getUserSettings,
  getCurrentUser,
  editCurrentUser,
  changeCurrentUserPassword,
  deleteCurrentUser,
  confirmAgreement,
  getUserData, createVisitor,
} from '../../../api/usersApi/userApi';
import {setIsDataLoading } from "../../productsReducer/productActions";

export const SET_USERS_LIST = 'users/setUserList';
export const IS_USER_LIST_LOADING = 'users/isUserListLoading';
export const SET_USER_SETTINGS = 'users/setUserSettings';
export const IS_SETTINGS_LOADING = 'users/isSettingsLoading';
export const SET_CURRENT_USER = 'users/setCurrentUser';
export const IS_CURRENT_USER_LOADING = 'users/isCurrentUserLoading';
export const SET_SELECTED_USER_DATA = 'users/setSelectedUserData';
export const SET_IS_SELECTED_USER_DATA_LOADING =
  'users/setIsSelectedUserDataLoading';

//Action Creator
export const setUsersList = (data) => {
  return { type: SET_USERS_LIST, payload: { data } };
};
export const setCurrentUser = (data) => {
  return { type: SET_CURRENT_USER, payload: { data } };
};
export const setUserSettings = (data) => {
  return { type: SET_USER_SETTINGS, payload: { data } };
};
export const setIsLoadingUsers = (data) => {
  return { type: IS_USER_LIST_LOADING, payload: { data } };
};
export const setIsCurrentUserLoading = (data) => {
  return { type: IS_CURRENT_USER_LOADING, payload: { data } };
};
export const setIsSettingsLoading = (data) => {
  return { type: IS_SETTINGS_LOADING, payload: { data } };
};
export const setSelectedUserData = (data) => {
  return { type: SET_SELECTED_USER_DATA, payload: data };
};
export const setIsSelectedUserDataLoading = (isLoading) => {
  return { type: SET_IS_SELECTED_USER_DATA_LOADING, payload: isLoading };
};
export const getCurrentUserThunk = () => async (dispatch) => {
  dispatch(setIsCurrentUserLoading(true));
  const response = await getCurrentUser();
  if (response.status >= 200 && response.status < 300) {
    dispatch(setCurrentUser(response.data));
    dispatch(setIsCurrentUserLoading(false));
  }
};

export const editCurrentUserThunk = (userData) => async (dispatch) => {
  const response = await editCurrentUser(userData);
  if (response.status < 200 || response.status >= 300) {
    dispatch(stopSubmit('UserInfoForm', { _error: response.data.errors }));
    dispatch(reset('UserInfoForm'));
    return Promise.reject(response.data.errors);
  }
};

export const deleteCurrentUserThunk = () => async () => {
  const response = await deleteCurrentUser();
  if (response.status < 200 || response.status >= 300) {
    return Promise.reject(response.data.errors);
  }
};

export const getUsersListThunk = (filter) => async (dispatch) => {
  dispatch(setIsLoadingUsers(true));
  const response = await getUsersList(filter);
  if (response.status === 200) {
    dispatch(setUsersList(response.data));
    dispatch(setIsLoadingUsers(false));
  }
};

export const getUserSettingsThunk = () => async (dispatch) => {
  dispatch(setIsDataLoading(true));
  
  const response = await getUserSettings();
  
  if (response.status === 200) {
    dispatch(setUserSettings(response.data));
    dispatch(setIsSettingsLoading(false));
  }
};

export const getSelectedUserDataThunk = (id) => async (dispatch) => {
  dispatch(setIsSelectedUserDataLoading(true));

  try {
    const response = await getUserData(id);

    if (response.status >= 200 && response.status < 300) {
      dispatch(setSelectedUserData(response.data));
      dispatch(setIsSelectedUserDataLoading(false));
    } else if (response.status === 404) {
      throw new Error("User not found");
    }
  } catch (error) {
    throw error;
  }
};

export const addUserThunk = (data) => async (dispatch) => {
  const response = await addUser(data);
  if (response.status < 200 || response.status >= 300) {
    dispatch(stopSubmit('UserForm', { _error: response.data.errors }));
    return Promise.reject(response.data.errors);
  }
};

export const editUserThunk = (userId, userData) => async (dispatch) => {
  const response = await editUser(userId, userData);
  if (response.status < 200 || response.status >= 300) {
    dispatch(stopSubmit('UserForm' + userId, { _error: response.data.errors }));
    dispatch(reset('UserForm' + userId));
    dispatch(stopSubmit('UserInfoForm', { _error: response.data.errors }));
    return Promise.reject(response.data.errors);
  }
};

export const deleteUserThunk = (userId) => async () => {
  const response = await deleteUser(userId);
  if (response.status < 200 || response.status >= 300) {
    return Promise.reject(response.data.errors);
  }
};

export const changeUserPasswordThunk = (userId, data) => async () => {
  const response = await changeUserPassword(userId, data);
  if (response.status < 200 || response.status >= 300) {
    return Promise.reject(response.data.errors);
  }
};

export const createVisitorThunk = (userId) => async () => {
  const response = await createVisitor(userId);
  if (response.status < 200 || response.status >= 300) {
    return Promise.reject(response.data.errors);
  }
};

export const changeCurrentUserPasswordThunk = (data) => async () => {
  const response = await changeCurrentUserPassword(data);
  if (response.status < 200 || response.status >= 300) {
    return Promise.reject(response.data.errors);
  }
};

export const confirmAgreementUserThunk = () => async () => {
  const response = await confirmAgreement();
  if (response.status < 200 || response.status >= 300) {
    return Promise.reject(response.data.errors);
  }
};