import {useCallback, useState} from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Box, Stack, Button, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

import { isNotEmpty } from './FieldLevelFormValidation';
import { NotificationDateField } from './FormComponents';

import {
  NotificationFormSelect,
  NotificationFormFieldTitle,
  NotificationFormFieldWrapper,
  NotificationFormContainer,
  NotificationFormHeader,
  NotificationFormHeaderLabel,
} from './styled';

import { useNavigationPrompt } from '../../../../hooks/navigation';
import NotificationLanguageTabs from '../../../../components/notificationComponents/NotificationLanguageTabs';
import ConfirmationDialog from '../../../../components/confirmationDialog/ConfirmationDialog';

let NotificationForm = (props) => {
  const {
    onFormEdit,
    onFormCancel,
    selectedLanguage,
    onDeleteNotification,
    isFormEdit,
    dirty,
    submitSucceeded,
    hideControlPanel,
  } = props;

  const blockNavigation = dirty && !submitSucceeded;
  useNavigationPrompt(blockNavigation);

  const { t } = useTranslation();

  const [showAlert, setShowAlert] = useState(false);

  const required = useCallback(value => (value ? undefined : t('required')), [selectedLanguage]);

  const onNotificationDelete = () => {
    setShowAlert(true);
  };

  const handleClose = () => {
    setShowAlert(false);
  };

  const onDeleteConfirmation = () => {
    onDeleteNotification(props.currentItem.id);
  };

  return (
    <NotificationFormContainer component="form" onSubmit={props.handleSubmit}>
      <Stack spacing={2}>
        <NotificationFormHeader>
          <NotificationFormHeaderLabel>
            <Typography
              fontWeight="bold"
              textTransform="uppercase"
              overflow="hidden"
              textOverflow="ellipsis"
              maxWidth={500}
            >
              {props.attractionName}
            </Typography>
          </NotificationFormHeaderLabel>
          {!hideControlPanel && (
            <Box display="flex" gap="10px">
              <Button
                variant="outlined"
                onClick={onFormCancel}
                type="button"
                disabled={!isFormEdit}
              >
                {t('notifications.cancel')}
              </Button>
              <>
                <Button
                  variant="outlined"
                  disabled={isFormEdit}
                  onClick={onFormEdit}
                  type="button"
                >
                  {t('notifications.edit')}
                </Button>
                <Button
                  variant="outlined"
                  onClick={onNotificationDelete}
                  type="button"
                >
                  {t('notifications.delete')}
                </Button>
              </>
              <LoadingButton
                sx={{ color: '#fff' }}
                variant="contained"
                startIcon={<SaveIcon />}
                loading={props.showSaveAnimation}
                loadingPosition="start"
                disabled={!isFormEdit}
                type="submit"
              >
                {t('notifications.save')}
              </LoadingButton>
            </Box>
          )}
        </NotificationFormHeader>

        <NotificationLanguageTabs
          isEdit={isFormEdit}
          currentItem={props.currentItem}
          languages={props.languages}
          selectedLanguage={selectedLanguage}
        />
        <NotificationFormFieldWrapper>
          <NotificationFormFieldTitle>
            {t('notifications.status')}:
          </NotificationFormFieldTitle>
          {isFormEdit ? (
            <Field
              name="status"
              component={NotificationFormSelect}
              validate={[required, isNotEmpty]}
            >
              {props.availableStatuses[0].parameters.map((status, idx) => (
                <option value={status.id} key={idx}>
                  {status.value[selectedLanguage] || status.value.en}
                </option>
              ))}
            </Field>
          ) : (
            <Field
              name="status"
              component={NotificationFormSelect}
              validate={[required, isNotEmpty]}
              disabled
              meta={{}}
              input={{ value: props.currentItem.status }}
            >
              {props.availableStatuses[0].parameters.map((status, idx) => (
                <option value={status.id} key={idx}>
                  {status.value[selectedLanguage] || status.value.en}
                </option>
              ))}
            </Field>
          )}
        </NotificationFormFieldWrapper>
        <NotificationFormFieldWrapper>
          <NotificationFormFieldTitle>
            {t('notifications.start_date')}:
          </NotificationFormFieldTitle>
          {isFormEdit ? (
            <Field
              component={NotificationDateField}
              name="startDate"
              validate={[required]}
            />
          ) : (
            <Field
              component={NotificationDateField}
              name="startDate"
              validate={[required]}
              disabled
              meta={{}}
              input={{ value: props.currentItem.startDate }}
            />
          )}
        </NotificationFormFieldWrapper>
        <NotificationFormFieldWrapper>
          <NotificationFormFieldTitle>
            {t('notifications.end_date')}:
          </NotificationFormFieldTitle>

          {isFormEdit ? (
            <Field
              component={NotificationDateField}
              name="endDate"
              validate={[required]}
            />
          ) : (
            <Field
              component={NotificationDateField}
              name="endDate"
              validate={[required]}
              disabled
              meta={{}}
              input={{ value: props.currentItem.endDate }}
            />
          )}
        </NotificationFormFieldWrapper>
      </Stack>
      <ConfirmationDialog
        showAlert={showAlert}
        handleClose={handleClose}
        handleCloseSuccess={onDeleteConfirmation}
        text={t('notifications.delete_notification_dialog')}
      />
    </NotificationFormContainer>
  );
};

const mapStateToProps = (_, ownProps) => {
  if (ownProps.currentItem?.id) {
    return {
      initialValues: {
        id: ownProps.currentItem.id,
        title: ownProps.currentItem.title,
        text: ownProps.currentItem.text,
        startDate: ownProps.currentItem.startDate,
        endDate: ownProps.currentItem.endDate,
        attractions: ownProps.currentItem.attractions,
        status: ownProps.currentItem.status,
      },
    };
  }
};

const validate = (values) => {
  const errors = {};
  if (values.startDate && values.endDate) {
    const startDate = new Date(Date.parse(values.startDate));
    const endDate = new Date(Date.parse(values.endDate));
    if (startDate > endDate)
      errors.endDate = 'notifications.end_date_validation_text';
  }
  return errors;
};

NotificationForm = reduxForm({
  form: 'NotificationForm',
  validate,
  enableReinitialize: true,
  touchOnChange: true,
})(NotificationForm);

export default connect(mapStateToProps)(NotificationForm);
