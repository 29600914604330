import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button, Box, CircularProgress, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import QuestionsForm from './QuestionsForm';

import useAPIError from '../../../apIErrorProvider/useAPIError';
import { SelectStatus, SelectCategory } from './questionsComponents/Select';
import { FAQ_QUESTION_STATUS } from '../../../consts/consts';

const QuestionsFaq = (props) => {
  const { addError } = useAPIError();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const appState = props.appReducer;
  const questionFaqState = props.faqQuestionReducer;
  const categoryFaqState = props.categoryFaqReducer;
  const globalSettings = props.appReducer;
  const [expanded, setExpanded] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [updateQuestion, setUpdateQuestion] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [statusValue, setStatusValue] = useState('');
  const [categoryValue, setCategoryValue] = useState('');
  const [isSave, setIsSave] = useState(false);

  const dictionaryCategoryData = categoryFaqState?.categoryData.reduce(
    (acc, current) => {
      acc[current.id] = current.name;
      return acc;
    },
    {}
  );

  useEffect(() => {
    props.getSearchQuestionThunk(
      statusValue !== '' ? statusValue : null,
      categoryValue !== '' ? categoryValue : null
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmitQuestionForm = (values) => {
    setIsSave(true);
    if (updateQuestion && isEdit) {
      const data = {
        id: questionFaqState.currentQuestion.id,
        order: values.order,
        categoryId: values.categoryId,
        status: values.status ? values.status : 'Active',
        title: values.title,
        text: values.text,
      };
      props
        .editQuestionThunk(questionFaqState.currentQuestion.id, data)
        .then(() => {
          setExpanded(false);
          setIsSave(false);
          setUpdateQuestion(false);
          setIsEdit(false);
          addError(`${t('faq_page.questions.error_edit_text')}`, 'Success');
          props.getSearchQuestionThunk(
            statusValue !== '' ? statusValue : null,
            categoryValue !== '' ? categoryValue : null
          );
        })
        .catch((error) => {
          addError(
            `${t('faq_page.questions.error_not_edit_text')}`,
            'Error',
            error
          );
          setIsSave(false);
        });
    }
  };

  const onAddQuestion = () => {
    navigate('new-question');
  };

  const onChangeStatus = (event) => {
    setStatusValue(event);
    props.getSearchQuestionThunk(
      event,
      categoryValue !== '' ? categoryValue : null
    );
  };

  const onChangeCategory = (event) => {
    setCategoryValue(event);
    props.getSearchQuestionThunk(
      statusValue !== '' ? statusValue : null,
      event
    );
  };

  const handleCloseSuccess = () => {
    setIsEdit(false);
    props
      .deleteQuestionThunk(questionFaqState.currentQuestion.id)
      .then(() => {
        setShowAlert(false);
        addError(`${t('faq_page.questions.error_deleted_text')}`, 'Success');
        props.getSearchQuestionThunk(
          statusValue !== '' ? statusValue : null,
          categoryValue !== '' ? categoryValue : null
        );
      })
      .catch((error) =>
        addError(
          `${t('faq_page.questions.error_not_deleted_text')}`,
          'Error',
          error
        )
      );
  };

  const handleExpandChange = (item) => (event, isExpanded) => {
    props.setCurrentQuestion(item);
    setIsEdit(false);
    setUpdateQuestion(true);
    setExpanded(isExpanded ? item.id : false);
  };

  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={
        <ArrowForwardIosSharpIcon
          sx={{
            fontSize: '0.9rem',
            transform: 'rotate(90deg)',
            marginRight: '55px',
          }}
        />
      }
      {...props}
    />
  ))(({ theme }) => ({
    padding: '0px',
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(180deg)',
      marginRight: '52px',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
      margin: '0px',
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));

  const statusOptionList = globalSettings.globalSettingsData.filter(
    (item) => item.name.en === FAQ_QUESTION_STATUS
  );

  if (!questionFaqState.isLoadingData) {
    return (
      <Box sx={{ position: 'absolute', top: '50%', right: '50%' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%', position: 'relative', marginTop: '40px' }}>
      <>
        <Box sx={{ width: '250px', marginBottom: '30px' }}>
          <Button
            variant="contained"
            onClick={onAddQuestion}
            sx={{ color: '#fff', width: '100%' }}
          >
            {t('faq_page.questions.add_button')}
          </Button>
        </Box>
        <Box sx={{ marginBottom: '30px', display: 'flex' }}>
          <Box sx={{ width: '150px', marginRight: '10px' }}>
            <SelectStatus
              data={statusOptionList}
              selectedLanguage={appState.selectedLanguage}
              onChangeStatus={onChangeStatus}
            />
          </Box>
          <Box sx={{ width: '340px' }}>
            <SelectCategory
              data={categoryFaqState.categoryData}
              selectedLanguage={appState.selectedLanguage}
              onChangeCategory={onChangeCategory}
            />
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              width: '100%',
              height: '48px',
              backgroundColor: 'rgba(0, 0, 0, 0.03)',
              border: '1px solid #e0e0e0',
              display: 'flex',
              borderBottom: 'none',
            }}
          >
            <Box
              sx={{
                maxWidth: '120px',
                width: '100%',
                height: '48px',
                borderRight: '1px solid #e0e0e0',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                padding: '0px 20px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {t('faq_page.questions.status')}
            </Box>
            <Box
              sx={{
                maxWidth: '120px',
                width: '100%',
                height: '48px',
                borderRight: '1px solid #e0e0e0',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                padding: '0px 20px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {t('faq_page.questions.order')}
            </Box>
            <Box
              sx={{
                maxWidth: '620px',
                width: '100%',
                height: '48px',
                borderRight: '1px solid #e0e0e0',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                padding: '0px 20px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {t('faq_page.questions.title')}
            </Box>
            <Box
              sx={{
                maxWidth: '317px',
                width: '100%',
                height: '48px',
                borderRight: '1px solid #e0e0e0',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                padding: '0px 20px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {t('faq_page.questions.category')}
            </Box>
            <Box
              sx={{
                maxWidth: '120px',
                width: '100%',
                height: '48px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '0px 20px',
              }}
            >
              {t('faq_page.questions.view')}
            </Box>
          </Box>
          {!questionFaqState.questionsData.length ? (
            <Box sx={{ marginTop: '20px', padding: '10px' }}>
              {t('faq_page.questions.no_questions')}
            </Box>
          ) : (
            questionFaqState.questionsData.map((item) => (
              <Accordion
                expanded={expanded === item.id}
                onChange={handleExpandChange(item)}
                key={item.id}
              >
                <AccordionSummary>
                  <Box
                    sx={{
                      maxWidth: '120px',
                      width: '100%',
                      height: '48px',
                      borderRight: '1px solid #e0e0e0',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      padding: '0px 20px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {item.status}
                  </Box>
                  <Box
                    sx={{
                      maxWidth: '120px',
                      width: '100%',
                      height: '48px',
                      borderRight: '1px solid #e0e0e0',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      padding: '0px 20px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {item.order}
                  </Box>
                  <Box
                    sx={{
                      maxWidth: '620px',
                      width: '100%',
                      height: '48px',
                      borderRight: '1px solid #e0e0e0',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      padding: '0px 20px',
                    }}
                  >
                    <Typography
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                    >
                      {item.title[globalSettings.selectedLanguage] ||
                        item.title.en}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      maxWidth: '317px',
                      width: '100%',
                      height: '48px',
                      borderRight: '1px solid #e0e0e0',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      padding: '0px 20px',
                    }}
                  >
                    <Typography
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                    >
                      {dictionaryCategoryData[item.categoryId][
                        globalSettings.selectedLanguage
                      ] || dictionaryCategoryData[item.categoryId].en}
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  {expanded === item.id && (
                    <QuestionsForm
                      onSubmit={onSubmitQuestionForm}
                      appState={appState}
                      questionFaqState={questionFaqState}
                      predefinedState={globalSettings.globalSettingsData.filter(
                        (item) => item.name.en === FAQ_QUESTION_STATUS
                      )}
                      isSave={isSave}
                      showAlert={showAlert}
                      setShowAlert={setShowAlert}
                      handleCloseSuccess={handleCloseSuccess}
                      isEdit={isEdit}
                      setIsEdit={setIsEdit}
                      updateQuestion={updateQuestion}
                      categoryFaqState={categoryFaqState}
                      selectedLanguage={globalSettings.selectedLanguage}
                    />
                  )}
                </AccordionDetails>
              </Accordion>
            ))
          )}
        </Box>
      </>
    </Box>
  );
};

export default QuestionsFaq;
