const ADMIN = 'admin';
const SYSTEM_MANAGER = 'systemmanager';
export const ATTRACTION_MANAGER = 'attraction';
export const ATTRACTION_MANAGER_PRIMARY = 'attractionprimary';

export const ADMIN_ROLE_LIST = ['admin', 'systemmanager'];
export const MANAGER_ROLE_LIST = ['attraction', 'attractionprimary'];

export const NAVIGATION_BUTTON_LIST = [
  {
    name: 'top_bar.main_title_attractions',
    link: '/attractions',
    showForRoles: [ADMIN, SYSTEM_MANAGER],
  },
  {
    name: 'top_bar.main_title_deals',
    link: '/deals',
    showForRoles: [ADMIN, SYSTEM_MANAGER],
  },
  {
    name: 'top_bar.main_title_points',
    link: '/points',
    showForRoles: [ADMIN, SYSTEM_MANAGER],
  },
  {
    name: 'top_bar.main_title_passes',
    link: '/passes',
    showForRoles: [ADMIN, SYSTEM_MANAGER],
  },
  {
    name: 'top_bar.main_title_visits',
    link: '/visits',
    showForRoles: [ADMIN, SYSTEM_MANAGER],
  },
  {
    name: 'top_bar.main_title_products',
    link: '/products',
    showForRoles: [ADMIN, SYSTEM_MANAGER],
  },
  {
    name: 'top_bar.main_title_notifications',
    link: '/notifications',
    showForRoles: [ADMIN, SYSTEM_MANAGER],
  },
  {
    name: 'top_bar.main_title_reports',
    link: '/reports',
    showForRoles: [ADMIN, SYSTEM_MANAGER],
  },
  {
    name: 'top_bar.main_title_reviews',
    link: '/reviews',
    showForRoles: [ADMIN, SYSTEM_MANAGER],
  },
  {
    name: 'top_bar.main_title_terminals',
    link: '/terminals',
    showForRoles: [ADMIN, SYSTEM_MANAGER],
  },
  {
    name: 'top_bar.main_title_settings',
    link: '/settings',
    showForRoles: [ADMIN, SYSTEM_MANAGER],
  },
  {
    name: 'top_bar.main_title_faq',
    link: '/faq',
    showForRoles: [ADMIN, SYSTEM_MANAGER],
  },
  {
    name: 'top_bar.main_title_publications',
    link: '/publishing',
    showForRoles: [ADMIN, SYSTEM_MANAGER],
  },
  {
    name: 'top_bar.main_title_users',
    link: '/users',
    showForRoles: [ADMIN, SYSTEM_MANAGER],
  },
  {
    name: 'top_bar.main_title_logs',
    link: '/logs',
    showForRoles: [ADMIN, SYSTEM_MANAGER],
  },
  {
    name: 'top_bar.main_title_my_profile',
    link: '/my-profile',
    showForRoles: [ADMIN, SYSTEM_MANAGER],
  },
  {
    name: 'top_bar.main_title_translation',
    link: '/translation',
    showForRoles: [ADMIN, SYSTEM_MANAGER],
  },
  {
    name: 'top_bar.main_title_app_version',
    link: '/app-version',
    showForRoles: [ADMIN, SYSTEM_MANAGER],
  },
];
