import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useNavigate,
  useParams,
  Outlet,
  useLocation,
  Link,
} from 'react-router-dom';

import { Box, Tab, Tabs, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';

import { attractionTabs, getOpenedTabIdByUrlParameter } from './attractionTabs';
import { a11yProps } from '../../components/tabPanel/TabPanel';
import { MANAGER_ROLE_LIST_APP } from './constants';
import { setTitle } from '../../redux/appReducer';

const useStyles = makeStyles({
  tabs: {
    '& .MuiTabs-flexContainer': {
      justifyContent: 'space-between',
    },
  },
  icon: {
    '&.MuiSvgIcon-root': {
      width: '100%',
      height: '100%',
    },
  },
  iconButton: {
    '&.MuiIconButton-root': {
      width: '30px',
      height: '30px',
      padding: 0,
      content: '',
      position: 'absolute',
      top: '7px',
      left: '-76px',
    },
  },
  wrapper: {
    maxWidth: '1300px',
    width: '100%',
    margin: '95px auto',
    position: 'relative',
  },
  container: {
    maxWidth: '1300px',
    width: '100%',
    margin: '0',
    position: 'relative',
  }
});

const Attraction = (props) => {
  const { appState, attractionsState, settingsState } = props;

  const { t } = useTranslation();
  const { id } = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const attractionStatus = attractionsState.currentAttraction.status;
  const currentUser = useSelector(state => state.userReducer.currentUser);
  const isManagerRoleSelected = MANAGER_ROLE_LIST_APP.includes(currentUser.role);

  const [activeTabId, setActiveTabId] = useState(
    getOpenedTabIdByUrlParameter(attractionTabs, location.pathname)
  );

  const handleTabsChange = (_, tabIndex) => {
    setActiveTabId(tabIndex);
  };

  const storedSearchParamsString = useSelector(
    (state) => state.attractionsReducer.attractionSearchParams
  );

  const onGetBack = () => { 
    const newUrl = isManagerRoleSelected ? `/atractions?${storedSearchParamsString}` : `/attractions?${storedSearchParamsString}`;
    navigate(newUrl);
  };

  useEffect(() => {
    if(attractionsState.currentAttraction.name) {
      dispatch((setTitle(attractionsState.currentAttraction.name)));
    }
  }, [attractionsState.currentAttraction.name])

  useEffect(() => {
    if (id) {
      props.getAttractionThunk(id);
      props.setAddElement(false);
    } else {
      props.setAddElement(true);
    }
    props.getCompaniesListThunk(
      appState.selectedDestination.id !== undefined
        ? appState.selectedDestination.id
        : settingsState.destinationData[0].id
    );
    props.getCategoryThunk();
    props.getPredefinedSettingsThunk();
    props.getFacilitiesThunk();
    props.getTagsThunk();
  }, [id, activeTabId]);

  useEffect(() => {
    setActiveTabId(
      getOpenedTabIdByUrlParameter(attractionTabs, location.pathname)
    );
  }, [location.pathname, activeTabId]);

  return (
    <Box component="div" className={isManagerRoleSelected? classes.container : classes.wrapper}>
      <Box mb="20px">
        <Button
          sx={{ color: '#ffffff' }}
          variant="contained"
          onClick={onGetBack}
        >
          {t('attractions.back_to_attraction_list')}
        </Button>
      </Box>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={activeTabId}
          className={classes.tabs}
          onChange={handleTabsChange}
          indicatorColor="primary"
          textColor="inherit"
        >
          {attractionTabs.map((tab, tabIndex) => {
            if (tabIndex === 0) {
              return (
                <Tab
                  component={Link}
                  to={tab.path}
                  label={t(tab.name)}
                  {...a11yProps(tabIndex)}
                  key={tabIndex}
                />
              );
            }

            if (tabIndex === 9) {
              if(!isManagerRoleSelected) {
                return (
                  <Tab
                    component={Link}
                    to={tab.path}
                    label={t(tab.name)}
                    x={{ width: '10%' }}
                    disabled={isManagerRoleSelected ? attractionStatus === 'Pending' ? true : false : attractionsState.addElement}
                    {...a11yProps(tabIndex)}
                    key={tabIndex}
                  />
                );
              }
            }
            else {
              return (
                <Tab
                  component={Link}
                  to={tab.path}
                  label={t(tab.name)}
                  x={{ width: '10%' }}
                  disabled={isManagerRoleSelected ? attractionStatus === 'Pending' ? true : false : attractionsState.addElement}
                  {...a11yProps(tabIndex)}
                  key={tabIndex}
                />
              );
            }
          })}
        </Tabs>
      </Box>
      <Box sx={{ p: 2 }}>
        <Outlet context={attractionsState} />
      </Box>
    </Box>
  );
};

export default Attraction;
