import { TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useTranslation } from 'react-i18next';
import { NotificationFormDatePicker } from './styled';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  textField: {
    '& .MuiFormHelperText-root': {
      maxWidth: '242px',
      bottom: '-18px',
      marginLeft: 0,
    },
    height: '52px'
  },
});

export const NotificationDateField = ({
  label,
  input,
  placeholder,
  meta: { touched, error },
  ...props
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const hasError = touched && error;
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <NotificationFormDatePicker
          {...props}
          {...input}
          inputFormat="dd.MM.yyyy"
          mask="__.__.____"
          value={input.value || null}
          onChange={input.onChange}
          disabled={props.disabled}
          clearable
          clearText={t('clear')}
          renderInput={({ inputProps, ...restParams }) => (
            <TextField
              {...restParams}
              inputProps={{
                ...inputProps,
                placeholder: placeholder,
              }}
              className={classes.textField}
              size="small"
              error={hasError}
              helperText={hasError && t(error)}
            />
          )}
        />
      </LocalizationProvider>
    </>
  );
};
