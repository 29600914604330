import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Box, CircularProgress } from '@mui/material';

import {
  ATTRACTION_CONTENT_DEFAULT_VALUES,
  ATTRACTION_SETUP_FORM_DEFAULT_VALUES,
  DEFAULT_PAGE_SIZE,
  ATTRACTION_MANAGER_ROLE,
  MANAGER_ROLE_LIST
} from './constants';
import SetupForm from './SetupForm';

import { attractionTabs } from '../attractionTabs';

import useAPIError from '../../../apIErrorProvider/useAPIError';
import { useNavigationPrompt } from '../../../hooks/navigation';
import { setTitle } from '../../../redux/appReducer';

const Setup = (props) => {
  const {
    tagsState,
    facilityState,
    categoryState,
    attractionsState,
    appState,
    predefinedState,
    settingsState,
    companyState,
    authState,
    setAttraction,
    getAttractionsListThunk,
    getAttractionListByRegionIdThunk,
    setAddElement,
    editAttractionSetupThunk,
    addAttractionsThunk,
    deleteAttractionsThunk,
    addAttractionPublicationThunk,
    getAttractionThunk,
  } = props;

  const { t } = useTranslation();
  const { addError } = useAPIError();
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();

  const [isEdit, setIsEdit] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const { currentAttraction, pageNumber } = attractionsState;

  const currentUser = useSelector(state => state.userReducer.currentUser);
  const isManagerRoleSelected = MANAGER_ROLE_LIST.includes(currentUser.role);

  const editAttractionDefaultValues = useMemo(() => {
    return {
      ...currentAttraction,
      type: currentAttraction.type === null ? '' : currentAttraction.type,
      entryType:
        currentAttraction.entryType === null ? '' : currentAttraction.entryType,
      mostPopular: currentAttraction.mostPopularInDestinationOrder || 0,
      mapLatitude:
        (currentAttraction.geoCoordinate &&
          currentAttraction.geoCoordinate.lat) ||
        0,
      mapLongtitude:
        (currentAttraction.geoCoordinate &&
          currentAttraction.geoCoordinate.lng) ||
        0,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAttraction.id]);

  const hasAttractionManagerRole = Array.isArray(authState.role)
    ? authState.role.some((currentRole) =>
        currentRole.includes(ATTRACTION_MANAGER_ROLE)
      )
    : authState.role === ATTRACTION_MANAGER_ROLE;

  const formMethods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      ...ATTRACTION_SETUP_FORM_DEFAULT_VALUES,
    },
  });

  const {
    watch,
    reset,
    setValue,
    formState: { isSubmitted, isDirty },
  } = formMethods;

  const blockNavigation = isDirty && !isSubmitted;

  useNavigationPrompt(blockNavigation);

  const locationValue = watch('locationId');
  const regionValue = watch('regionId');
  const areaValue = watch('areaId');
  const statusValue = watch('status');
  const mapLatitudeValue = watch('mapLatitude');
  const mapLongtitudeValue = watch('mapLongtitude');
  const [coordinates, setCoordinates] = useState({
    lat: mapLatitudeValue,
    lng: mapLongtitudeValue,
  });

  const handleUpdateCoordinates = (e) => {
    setCoordinates((prevState) => ({
      ...prevState,
      lat: +e.latLng.lat(),
      lng: +e.latLng.lng(),
    }));
    setValue('mapLatitude', +e.latLng.lat());
    setValue('mapLongtitude', +e.latLng.lng());
  };

  const handleChangeMapLatitude = (value) => {
    setCoordinates((prevState) => ({
      ...prevState,
      lat: Number(value),
    }));
  };

  const handleChangeMapLongtitude = (value) => {
    setCoordinates((prevState) => ({
      ...prevState,
      lng: Number(value),
    }));
  };

  const handleCancelChanges = () => {
    reset(
      id ? editAttractionDefaultValues : ATTRACTION_SETUP_FORM_DEFAULT_VALUES,
      { keepDefaultValues: true }
    );
    setIsEdit(false);
  };

  const onSubmit = (data) => {
    setIsSave(true);

    const submitData = {
      ...data,
      status: isManagerRoleSelected ? 'Pending' : data.status,
      type: data.type || null,
      entryType: data.entryType || null,
      regionOrder: data.regionOrder || 0,
      areaOrder: data.areaOrder || 0,
      locationOrder: data.locationOrder || 0,
      destinationId: appState.selectedDestination.id,
      mostPopularInDestinationOrder: data.mostPopular || 0,
      mapCoordinate: {
        lat: data.mapLatitude || 0,
        lng: data.mapLongtitude || 0,
      }
    };

    if (id) {
      const updateData = {
        ...submitData,
        id: currentAttraction.id
      };

      editAttractionSetupThunk(attractionsState.currentAttraction.id, updateData)
        .then(() => {
          getAttractionThunk(id);
          setIsEdit(false);
          setIsSave(false);
          addError(`${t('attractions.setup.error_edit_text')}`, 'Success');
        })
        .catch((error) => {
          setIsSave(false);
          addError(
            `${t('attractions.setup.error_not_edit_text')}`,
            'Error',
            error
          );
        });
    } else {
      const submit = {
        ...submitData,
        ...ATTRACTION_CONTENT_DEFAULT_VALUES
      };

      addAttractionsThunk(submit)
        .then((response) => {
          getAttractionsListThunk({
            destinationId: appState.selectedDestination.id,
            pageNumber,
            pageSize: props.attractionsState.pageSize,
          });
          setAddElement(true);
          setAttraction(response.data);
          navigate(
            `${isManagerRoleSelected ? '/atractions' : '/attractions'}/${response.data.id}/${attractionTabs[0].path}`
          );
          setIsEdit(false);
          setIsSave(false);
          addError(`${t('attractions.setup.error_add_text')}`, 'Success');
        })
        .catch((error) => {
          setIsSave(false);
          addError(
            `${t('attractions.setup.error_not_add_text')}`,
            'Error',
            error
          );
        });
    }
  };

  const handleDeleteAttraction = () => {
    deleteAttractionsThunk(attractionsState.currentAttraction.id)
      .then(() => {
        navigate(isManagerRoleSelected ? '/atractions' : '/attractions');
        setShowAlert(false);
        setIsEdit(false);
        addError(`${t('attractions.setup.error_deleted_text')}`, 'Success');
      })
      .catch((error) => {
        addError(
          `${t('attractions.setup.error_not_deleted_text')}`,
          'Error',
          error
        );
        setShowAlert(false);
      });
  };

  const handleAttractionPublication = () => {
    addAttractionPublicationThunk(currentAttraction.id)
      .then(() => {
        getAttractionThunk(currentAttraction.id);
        addError(`${t('attractions.setup.error_edit_text')}`, 'Success');
      })
      .catch((error) => {
        addError(
          `${t('attractions.setup.error_not_edit_text')}`,
          'Error',
          error
        );
      });
  }

  useEffect(() => {
    if(location.pathname === '/attractions/new' || location.pathname === '/atractions/new') {
      dispatch((setTitle('attractions.new_attraction')));
    }
  }, [])

  useEffect(() => {
    if (id && editAttractionDefaultValues?.name) {
      reset(editAttractionDefaultValues);
      setCoordinates((prevState) => ({
        ...prevState,
        lat: editAttractionDefaultValues.mapLatitude,
        lng: editAttractionDefaultValues.mapLongtitude,
      }));
    }
  }, [editAttractionDefaultValues, id, reset]);

  useEffect(() => {
    getAttractionListByRegionIdThunk({
      destinationId: appState.selectedDestination.id,
      region: currentAttraction.regionId,
    });
  }, [
    appState.selectedDestination.id,
    currentAttraction?.regionId,
    getAttractionListByRegionIdThunk,
  ]);

  return (
    <Box sx={{ width: '100%' }}>
      {id && !editAttractionDefaultValues?.name ? (
        <Box component="div" sx={{ width: '100%', position: 'relative' }}>
          <CircularProgress
            sx={{ position: 'absolute', right: '50%', top: '300px' }}
          />
        </Box>
      ) : (
        <FormProvider {...formMethods}>
          <SetupForm
            handleDeleteAttraction={handleDeleteAttraction}
            settingsState={settingsState}
            appState={appState}
            attractionsState={attractionsState}
            categoryState={categoryState}
            facilityState={facilityState}
            tagsState={tagsState}
            predefinedState={predefinedState}
            companyState={companyState}
            setIsEdit={setIsEdit}
            isEdit={isEdit}
            isSave={isSave}
            showAlert={showAlert}
            setShowAlert={setShowAlert}
            hasAttractionManagerRole={hasAttractionManagerRole}
            locationValue={locationValue}
            regionValue={regionValue}
            areaValue={areaValue}
            statusValue={statusValue}
            onSubmit={onSubmit}
            onAttractionPublication={handleAttractionPublication}
            handleCancelChanges={handleCancelChanges}
            handleUpdateCoordinates={handleUpdateCoordinates}
            coordinates={coordinates}
            handleChangeMapLatitude={handleChangeMapLatitude}
            handleChangeMapLongtitude={handleChangeMapLongtitude}
          />
        </FormProvider>
      )}
    </Box>
  );
};

export default Setup;
