import {
  addDealApi,
  getDealsListApi,
  getAllDealsListApi,
  editDealApi,
  deleteDealApi,
  addDealApproveApi
} from '../../../api/dealsApi.js';
import { stopSubmit } from 'redux-form';

export const SET_DEALS_LIST = 'SET_DEALS_LIST';
export const IS_DEALS_LOADING = 'IS_DEALS_LOADING';
export const IS_ITEM_UPDATING = 'IS_ITEM_UPDATING';
export const SET_TABLE_SETTINGS = 'SET_TABLE_SETTINGS';

//Action Creator
export const setDealsList = (data) => {
  return { type: SET_DEALS_LIST, payload: { data } };
};

export const setIsDataLoading = (isLoading) => {
  return { type: IS_DEALS_LOADING, isLoading };
};

export const setIsItemUpdating = (isUpdating) => {
  return { type: IS_ITEM_UPDATING, isUpdating };
};

export const setTableSettings = (data) => {
  return { type: SET_TABLE_SETTINGS, payload: { data } };
};

//Thunks
export let addDealThunk = (attractionId, data) => async (dispatch) => {
  const response = await addDealApi(attractionId, data);
  if (response.status < 200 || response.status >= 300) {
    dispatch(stopSubmit('DealAddForm', { _error: response.data.errors }));
    return Promise.reject(response.data.errors);
  }
};

export let getDealsListThunk =
  ({ attractionId, pageNumber, pageSize, status }) =>
  async (dispatch) => {
    dispatch(setIsDataLoading(true));
    let response = await getDealsListApi({
      attractionId,
      pageNumber,
      pageSize,
      status,
    });
    if (response.status === 200) {
      dispatch(setDealsList(response.data.items));
      dispatch(
        setTableSettings({
          totalItems: response.data.totalCount,
          totalPages: response.data.totalPages,
          pageNumber: pageNumber,
          pageSize: pageSize,
        })
      );
      dispatch(setIsDataLoading(false));
    }
  };

export let editDealThunk = (dealId, data) => async (dispatch) => {
  const response = await editDealApi(dealId, data);
  if (response.status < 200 || response.status >= 300) {
    dispatch(stopSubmit('DealForm', { _error: response.data.errors }));
    return Promise.reject(response.data.errors);
  }
};

export let deleteDealThunk = (dealId) => async () => {
  const response = await deleteDealApi(dealId);
  if (response.status < 200 || response.status >= 300) {
    return Promise.reject(response.data.errors);
  }
};

export let addDealApproveThunk = (dealId, data) => async (dispatch) => {
  let response = await addDealApproveApi(dealId, data);
  if (response.status < 200 || response.status >= 300) {
    dispatch(stopSubmit('DealForm', { _error: response.data.errors }));
    return Promise.reject(response.data.errors);
  }
  return response;
}

export let getAllDealsListThunk =
  ({ destinationId, attractionId, pageNumber, pageSize, status }) =>
  async (dispatch) => {
    dispatch(setIsDataLoading(true));
    let response = await getAllDealsListApi({
      destinationId,
      attractionId,
      pageNumber,
      pageSize,
      status,
    });
    if (response.status === 200) {
      dispatch(setDealsList(response.data.items));
      dispatch(
        setTableSettings({
          totalItems: response.data.totalCount,
          totalPages: response.data.totalPages,
          pageNumber: pageNumber,
          pageSize: pageSize,
        })
      );
      dispatch(setIsDataLoading(false));
    }
  };