import { styled } from '@mui/system';
import {
  Box,
  FormControl,
  FormHelperText,
  Select,
  TextField,
} from '@mui/material';
import { ReduxSelect } from '../../../components/reduxFormComponents/reduxFormComponents';
import { DatePicker } from '../../../components/notificationComponents/DatePicker';
import { ReduxAutocomplete } from '../../../components/notificationComponents/AutocompleteInput';
import { DesktopDatePicker, MobileDatePicker } from '@mui/lab';
import TextEditor from '../../../components/RichTextEditor/TextEditor';

export const NotificationFormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 16px;
  margin-top: 14px;
`;

export const NotificationFormHeader = styled(Box)`
  display: flex;
`;

export const NotificationFormHeaderLabel = styled(Box)`
  display: flex;
  width: 100%;
`;

export const NotificationFormControlPanel = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 8px;
  margin-bottom: 16px;
`;

export const NotificationFormFieldWrapper = styled(Box)`
  display: flex;
  margin-top: 16px;
  align-items: center;
`;

export const NotificationFormFieldTitle = styled(Box)`
  white-space: nowrap;
  max-width: 215px;
  width: 100%;
`;

export const NotificationFormSelect = styled(ReduxSelect)`
  max-width: 215px;
  width: 100%;
`;

export const NotificationFormDatePicker = styled(DesktopDatePicker)`
  max-width: 215px;
  width: 100%;
`;

export const NotificationFormAutocomplete = styled(ReduxAutocomplete)`
  max-width: 400px;
  width: 100%;
  & .Mui-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.9);
  }
  .MuiChip-root.Mui-disabled {
    opacity: 0.9;
    pointer-events: none;
  }
  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -18px;
    margin-left: 0;
  }
`;

export const NotificationAddFormControlPanel = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: center;
`;

export const NotificationEditFormControlPanel = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 16px;
`;

export const NotificationFormDateField = styled(MobileDatePicker)`
  max-width: 215px;
  width: 100%;
`;

export const NotificationFormDateFieldWrapper = styled(Box)`
  display: flex;
  margin-top: 20px;
  align-items: center;

  & .MuiOutlinedInput-root {
    max-width: 215px;
    width: 100%;
  }
`;

export const NotificationDateTextField = styled(TextField)`
  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -18px;
    margin-left: 0;
  }

  & .MuiOutlinedInput-input {
    cursor: pointer;
  }

  & .MuiOutlinedInput-root {
    padding-right: 0;
  }

  & .MuiInputAdornment-root {
    cursor: pointer;
  }
`;

export const NotificationFormSelectField = styled(Select)`
  max-width: 215px;
  width: 100%;
`;

export const StyledFormControl = styled(FormControl)`
  .MuiInputLabel-root[data-shrink='false'] {
    top: -8px;
  }
  max-width: 700px;
`;

export const NotificationFormSelectFieldInput = styled(Select)`
  width: 100%;
`;

export const SelectHelperText = styled(FormHelperText)`
  position: absolute;
  margin-left: 0;
  bottom: -18px;
`;

export const NotificationLanguageTabField = styled(Box)`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

export const NotificationFormLanguageTabField = styled(TextField)`
  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -18px;
    margin-left: 0;
  }
`;

export const NotificationFormTextEditor = styled(TextEditor)`
  width: 100%;
  overflow-y: auto;
  max-height: 600px;
`;
