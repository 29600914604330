import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CircularProgress } from '@mui/material';

import AddNewNotificationForm from './AddNotificationForm';

import { formatLanguageFieldsData } from '../helpers';

import useAPIError from '../../../../apIErrorProvider/useAPIError';
import { NOTIFICATION_STATUS } from '../../../../consts/consts';
import { getSmallAttractionsDataThunk } from '../../../../redux/attractionsReducers/attractions/attractionsActions';
import {
  getDestinationNotificationsListThunk,
  addDestinationNotificationThunk,
} from '../../../../redux/generalNotificationsReducer/generalNotificationsActions';
import {
  removeKeysWithEmptyValueArrays,
  removeTextEditorValueKeysWithNoPlainText,
} from '../../../../helpers/translationObjectUtils';

const AddNewNotificationFormContainer = ({
  statusOptions,
  selectedLanguage,
  availableAttractions,
  selectedDestinationId,
  languages,
}) => {
  const { t } = useTranslation();
  const { addError } = useAPIError();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showSaveAnimation, setShowSaveAnimation] = useState(false);

  const onSubmit = (values) => {
    setShowSaveAnimation(true);

    const fieldsWithTranslations = formatLanguageFieldsData(values);

    const newNotification = {
      destinationId: selectedDestinationId,
      status: values.status,
      startDate: new Date(values.startDate).toISOString(),
      endDate: new Date(values.endDate).toISOString(),
      isGeneralNotification: values.isGeneralNotification,
      isVisibleForGeneralSection: values.isGeneralNotification
        ? true
        : values.isVisibleForGeneralSection,
      attractions: values.attractions,
      title: removeKeysWithEmptyValueArrays(fieldsWithTranslations.title),
      text: removeTextEditorValueKeysWithNoPlainText(
        fieldsWithTranslations.text
      ),
    };

    dispatch(addDestinationNotificationThunk(newNotification))
      .then(() => {
        dispatch(getDestinationNotificationsListThunk(selectedDestinationId));
        setShowSaveAnimation(false);
      })
      .then(() => {
        addError(`${t('notifications.success_add_notification')}`, 'Success');
        navigate(-1);
      })
      .catch(() => {
        addError(`${t('notifications.error_add_notification')}`, 'Error');
        setShowSaveAnimation(false);
      });
  };

  useEffect(() => {
    dispatch(getSmallAttractionsDataThunk(selectedDestinationId));
  }, [dispatch, selectedDestinationId]);

  return (
    <>
      {languages && statusOptions && availableAttractions ? (
        <AddNewNotificationForm
          statusOptions={statusOptions}
          selectedLanguage={selectedLanguage}
          availableAttractions={availableAttractions}
          languages={languages}
          showSaveAnimation={showSaveAnimation}
          onSubmit={onSubmit}
        />
      ) : (
        <CircularProgress />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const selectedDestinationLanguages =
    state.appReducer.selectedDestination.languages;

  const statusOptions = state.appReducer.globalSettingsData.filter(
    (item) => item.name.en === NOTIFICATION_STATUS
  )[0].parameters;

  

  const availableAttractions = state.attractionsReducer.attractionsSmallData
  .filter(function(attraction) {
    return attraction.status === 'Active';
  })
  .sort(function(a, b) {
    var textA = a.name.toUpperCase().trim();
    var textB = b.name.toUpperCase().trim();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });

  console.log('availableAttractions: ', availableAttractions);

  const selectedDestinationId = state.appReducer.selectedDestination.id;

  return {
    languages: selectedDestinationLanguages,
    currentAttractionId: state.attractionsReducer.currentAttraction.id,
    selectedLanguage: state.appReducer.selectedLanguage,
    statusOptions,
    availableAttractions,
    selectedDestinationId,
  };
};

export default connect(mapStateToProps)(AddNewNotificationFormContainer);
