import {useCallback, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import SaveIcon from '@mui/icons-material/Save';
import { Box, Button, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { NotificationDateField } from './FormComponents';
import {
  NotificationFormContainer,
  NotificationFormFieldTitle,
  NotificationFormFieldWrapper,
  NotificationFormHeader,
  NotificationFormHeaderLabel,
  NotificationFormSelect,
} from './styled';

import useAPIError from '../../../../apIErrorProvider/useAPIError';
import NotificationLanguageTabs from '../../../../components/notificationComponents/NotificationLanguageTabs';

import {
  getAttractionNotificationsListThunk,
  addAttractionNotificationThunk,
} from '../../../../redux/attractionsReducers/notificationReducer/notificationActions';
import { useNavigationPrompt } from '../../../../hooks/navigation';
import {
  removeKeysWithEmptyValueArrays,
  removeTextEditorValueKeysWithNoPlainText,
} from '../../../../helpers/translationObjectUtils';

import { NOTIFICATION_STATUS, MANAGER_ROLE_LIST } from '../../../../consts/consts';

const validate = (values) => {
  const errors = {};
  if (values.startDate && values.endDate) {
    const startDate = new Date(Date.parse(values.startDate));
    const endDate = new Date(Date.parse(values.endDate));
    if (startDate > endDate)
      errors.endDate = 'End Date must be greater than Start Date';
  }
  return errors;
};

let NewNotificationForm = (props) => {
  const {
    dirty,
    submitSucceeded,
    languages,
    currentAttraction,
    selectedDestinationId,
    statusOptions,
    selectedLanguage,
    handleSubmit,
  } = props;

  const { t } = useTranslation();
  const { addError } = useAPIError();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const blockNavigation = dirty && !submitSucceeded;
  useNavigationPrompt(blockNavigation);

  const [showSaveAnimation, setShowSaveAnimation] = useState(false);

  const currentUser = useSelector(state => state.userReducer.currentUser);
  const isManagerRoleSelected = MANAGER_ROLE_LIST.includes(currentUser.role);

  const mappedDestinationLanguages = languages?.map((lang) => {
    return {
      id: lang,
      value: lang,
    };
  });

  const required = useCallback(value => value ? undefined : t('required'), [selectedLanguage]);
  const handleCancel = () => navigate(-1);

  const onSubmit = (values) => {
    setShowSaveAnimation(true);

    const newNotification = {
      attractionId: currentAttraction.id,
      destinationId: selectedDestinationId,
      title: removeKeysWithEmptyValueArrays(values.title),
      text: removeTextEditorValueKeysWithNoPlainText(values.text),
      status: values.status,
      startDate: new Date(values.startDate).toISOString(),
      endDate: new Date(values.endDate).toISOString(),
      isGeneralNotification: false,
    };

    dispatch(addAttractionNotificationThunk(newNotification))
      .then(() => {
        getAttractionNotificationsListThunk(currentAttraction.id);
        setShowSaveAnimation(false);
        addError(`${t('notifications.success_add_notification')}`, 'Success');
        navigate(isManagerRoleSelected ? `/atractions/${currentAttraction.id}/notifications` : `/attractions/${currentAttraction.id}/notifications`);
      })
      .catch((error) => {
        addError(
          `${t('notifications.error_add_notification')}`,
          'Error',
          error
        );
        setShowSaveAnimation(false);
      });
  };

  return (
    <NotificationFormContainer
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack spacing={2}>
        <NotificationFormHeader>
          <NotificationFormHeaderLabel>
            <Typography fontWeight="bold" textTransform="uppercase">
              {t('notifications.new_notification')}
            </Typography>
          </NotificationFormHeaderLabel>
          <Box display="flex" gap="10px">
            <Button variant="outlined" onClick={handleCancel} type="button">
              {t('notifications.cancel')}
            </Button>
            <LoadingButton
              sx={{ color: '#fff' }}
              variant="contained"
              startIcon={<SaveIcon />}
              loading={showSaveAnimation}
              loadingPosition="start"
              type="submit"
            >
              {t('notifications.save')}
            </LoadingButton>
          </Box>
        </NotificationFormHeader>

        <NotificationLanguageTabs
          isEdit
          languages={mappedDestinationLanguages}
          selectedLanguage={selectedLanguage}
        />
        <NotificationFormFieldWrapper>
          <NotificationFormFieldTitle>
            {t('notifications.status')}:
          </NotificationFormFieldTitle>
          <Field
            name="status"
            component={NotificationFormSelect}
            validate={required}
          >
            {statusOptions[0].parameters.map((status, idx) => (
              <option value={status.id} key={idx}>
                {status.value[selectedLanguage] || status.value.en}
              </option>
            ))}
          </Field>
        </NotificationFormFieldWrapper>

        <NotificationFormFieldWrapper>
          <NotificationFormFieldTitle>
            {t('notifications.start_date')}:
          </NotificationFormFieldTitle>
          <Field
            component={NotificationDateField}
            name="startDate"
            validate={[required]}
            placeholder={t('notifications.date')}
          />
        </NotificationFormFieldWrapper>

        <NotificationFormFieldWrapper>
          <NotificationFormFieldTitle>
            {t('notifications.end_date')}:
          </NotificationFormFieldTitle>

          <Field
            component={NotificationDateField}
            name="endDate"
            validate={[required]}
            placeholder={t('notifications.date')}
          />
        </NotificationFormFieldWrapper>
      </Stack>
    </NotificationFormContainer>
  );
};

const mapStateToProps = (state) => {
  const languages = state.appReducer.selectedDestination.languages;
  const selectedDestinationId = state.appReducer.selectedDestination.id;
  const selectedLanguage = state.appReducer.selectedLanguage;
  const currentAttraction = state.attractionsReducer.currentAttraction;
  const statusOptions = state.appReducer.globalSettingsData.filter(
    (item) => item.name.en === NOTIFICATION_STATUS
  );

  return {
    languages,
    statusOptions,
    selectedLanguage,
    currentAttraction,
    selectedDestinationId,
    initialValues: {
      status: 'Inactive',
      title: {},
      text: {},
      startDate: null,
      endDate: null,
    },
  };
};

NewNotificationForm = reduxForm({
  form: 'NewNotificationForm',
  validate,
  enableReinitialize: true,
  touchOnChange: true,
})(NewNotificationForm);

export default connect(mapStateToProps)(NewNotificationForm);
