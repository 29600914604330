import * as axios from 'axios';
import authService from '../../auth/AuthorizeService';
import { ACCOUNT_BASE_URL } from '../../config.js';

const URL = ACCOUNT_BASE_URL;

export const getCurrentUser = async () => {
  const token = await authService.getAccessToken();
  const url = `${URL}/api/Users/current`;
  return await axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const getUsersList = async (filter) => {
  const token = await authService.getAccessToken();
  let url = `${URL}/api/Users`;
  const filterParams = [];

  if (filter.status) {
    filterParams.push(`status=${filter.status}`);
  }
  if (filter.idCompany) {
    filterParams.push(`idCompany=${filter.idCompany}`);
  }
  if (filter.nameOrEmail) {
    filterParams.push(`nameOrEmail=${filter.nameOrEmail}`);
  }
  if (filter.role) {
    filterParams.push(`role=${filter.role}`);
  }
  if (filterParams.length) {
    url += '?' + filterParams.join('&');
  }

  return await axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const getUserSettings = async () => {
  const token = await authService.getAccessToken();
  const url = `${URL}/api/dictionaries/settings/users`;
  return await axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const getUserData = async (id) => {
  const token = await authService.getAccessToken();
  const url = `${URL}/api/Users/${id}`;
  return await axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const addUser = async (data) => {
  const token = await authService.getAccessToken();
  const url = `${URL}/api/Users`;
  return await axios
    .post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const editUser = async (userId, userData) => {
  const token = await authService.getAccessToken();
  const url = `${URL}/api/Users/${userId}`;
  return await axios
    .put(url, userData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const editCurrentUser = async (userData) => {
  const token = await authService.getAccessToken();
  const url = `${URL}/api/Users/current`;
  return await axios
    .put(url, userData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const changeCurrentUserPassword = async (data) => {
  const token = await authService.getAccessToken();
  const url = `${URL}/api/Users/current/changePassword`;
  return await axios
    .post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const deleteUser = async (userId) => {
  const token = await authService.getAccessToken();
  const url = `${URL}/api/Users/${userId}`;
  return await axios
    .delete(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const deleteCurrentUser = async () => {
  const token = await authService.getAccessToken();
  const url = `${URL}/api/Users/current`;
  return await axios
    .delete(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const changeUserPassword = async (userId, data) => {
  const token = await authService.getAccessToken();
  const url = `${URL}/api/Users/${userId}/changePassword`;
  return await axios
    .post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const confirmAgreement = async () => {
  const token = await authService.getAccessToken();
  const url = `${URL}/api/Users/current/confirmAgreement`;
  return await axios
    .put(url, {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const createVisitor = async (userId) => {
  const token = await authService.getAccessToken();
  const url = `${URL}/api/Users/${userId}/visitor`;
  return await axios
      .post(url, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .catch((error) => {
        return error.response;
      });
};