import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Button,
} from '@mui/material';
import {
    VisitorsDialogFormFieldTitle,
    VisitorsFormFieldWrapper
} from "../../styled";
import { useSelector } from "react-redux";
import UserCreationDialogSelectField from "./UserCreationDialogSelectField";
import {
    USER_ROLE_SETTINGS_ENGLISH_NAME,
    USER_ADMIN_ROLE,
    USER_SYSTEM_MANAGER_ROLE,
    ACTIVE_COMPANY_STATUS
} from "../../constants";

const UserCreationDialog = ({showAlert, handleClose, handleCloseSuccess, text}) => {
    const {t} = useTranslation();
    
    const currentLanguage = useSelector(state => state.appReducer.selectedLanguage);
    const roles = useSelector(state => state.userReducer.userSettings.find(x => x.name.en === USER_ROLE_SETTINGS_ENGLISH_NAME));
    const companies = useSelector(state => state.companyReducer.companiesData.filter(x => x.status === ACTIVE_COMPANY_STATUS));
    const defaultCompany = useSelector(state => state.companyReducer.defaultCompany);
    
    const [role,setRole] = useState(roles.parameters[0].id);
    const [company,setCompany] = useState(companies[0]?.id);
    
    const handleDialogSubmit = () => {
        let userCompany = role === USER_ADMIN_ROLE || role === USER_SYSTEM_MANAGER_ROLE ? defaultCompany.id : company;
        handleCloseSuccess(userCompany, role);
        setRole(roles.parameters[0].id);
        setCompany(companies[0].id);
    };

    const handleRoleValueChange = (value) => {
        setRole(value);
    };

    const handleCompanyValueChange = (value) => {
        setCompany(value);
    };

    return (
        <Dialog
            open={showAlert}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent sx={{padding: '25px 70px'}}>
                <DialogContentText>{text}</DialogContentText>
            </DialogContent>
            <VisitorsFormFieldWrapper>
                <VisitorsDialogFormFieldTitle>
                    {t('visitors.formFieldLabels.role')}
                </VisitorsDialogFormFieldTitle>
                <UserCreationDialogSelectField
                    name="role"
                    options={roles?.parameters}
                    renderOptions={(options) => {
                        if (!options || !options.length) return null;

                        return options.map((option, index) => {
                            return (
                                <option value={option.id} key={index}>
                                    {option.value[currentLanguage]}
                                </option>
                            );
                        });
                    }}
                    onChange={handleRoleValueChange}
                    required
                />
            </VisitorsFormFieldWrapper>
            {(role === 'AttractionManager' || role === 'AttractionManagerPrimary') && (
            <VisitorsFormFieldWrapper>
                <VisitorsDialogFormFieldTitle>
                    {t('visitors.formFieldLabels.company')}
                </VisitorsDialogFormFieldTitle>
                <UserCreationDialogSelectField
                    name="role"
                    options={companies}
                    renderOptions={(options) => {
                        if (!options || !options.length) return null;

                        return options.map((option, index) => {
                            return (
                                <option value={option.id} key={index}>
                                    {option.name}
                                </option>
                            );
                        });
                    }}
                    onChange={handleCompanyValueChange}
                    required
                />
            </VisitorsFormFieldWrapper>
            )}
            <DialogActions sx={{justifyContent: 'center'}}>
                <Button onClick={handleClose}>{t('no')}</Button>
                <Button onClick={handleDialogSubmit}>{t('yes')}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default UserCreationDialog;