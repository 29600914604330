export const getAvailableCategoriesForDestination = (
  allCategories,
  companyDestinationId,
  defaultCompanyDestinationId,
  selectedDestinationId
) => {
  if (!companyDestinationId) return [];

  if (defaultCompanyDestinationId === companyDestinationId) {
    return allCategories.reduce((acc, currentCategory) => {
      if (
        currentCategory.availableForDestination.includes(selectedDestinationId)
      ) {
        acc.push({
          id: currentCategory.id,
          name: currentCategory.name,
        });
      }

      return acc;
    }, []);
  }

  const availableForDestinationCategories = allCategories.reduce(
    (acc, currentCategory) => {
      if (
        currentCategory.availableForDestination.includes(companyDestinationId)
      ) {
        acc.push({
          id: currentCategory.id,
          name: currentCategory.name,
        });
      }

      return acc;
    },
    []
  );

  return availableForDestinationCategories;
};

export const getRegionsIncludedInDestination = (
  allRegions,
  companyDestinationId,
  defaultCompanyDestinationId,
  selectedDestinationId
) => {
  if (!companyDestinationId) return [];

  if (defaultCompanyDestinationId === companyDestinationId) {
    return allRegions.reduce((acc, currentRegion) => {
      if (currentRegion.destinationId === selectedDestinationId) {
        acc.push({
          id: currentRegion.id,
          name: currentRegion.title,
        });
      }

      return acc;
    }, []);
  }

  const regionsIncludedInDestination = allRegions.reduce(
    (acc, currentRegion) => {
      if (currentRegion.destinationId === companyDestinationId) {
        acc.push({
          id: currentRegion.id,
          name: currentRegion.title,
        });
      }

      return acc;
    },
    []
  );

  return regionsIncludedInDestination;
};

export const replaceFieldName = (itemsList, fromKey, toKey) => {
  const formatedItems = itemsList.map((item) => {
    if (item[toKey]) return item;
    return {
      [toKey]: item[fromKey],
    };
  });
  return formatedItems;
};

export const getSmallDataFromList = (itemsList) => {
  const formatedItems = itemsList.map((item) => {
    return {
      id: item.id,
      name: item.name,
    };
  });
  return formatedItems;
};

export const getUserItemsNames = (userItems, allItems, keyString) => {
  let filteredItems = [];

  if (!userItems) return;

  for (let i = 0; i < userItems.length; i++) {
    allItems.map((item) => {
      if (item.id === userItems[i][keyString]) {
        filteredItems.push(item.name.en || item.name);
      }
    });
  }

  if (filteredItems.length === 0 && userItems.length !== 0)
    return ['No match data'];
  else return filteredItems;
};
