import { Controller, useFormContext } from 'react-hook-form';

import { FormControl } from '@mui/material';

import { SelectHelperText, SetupFormSelectFieldInput } from '../styled';
import {useTranslation} from "react-i18next";

const createOptionElement = (optionsName, optionsData, selectedLanguage) => {
  return optionsData.map(
    (item) =>
      item.name.en === optionsName &&
      item.parameters.map((parameter) => (
        <option value={parameter.id} key={parameter.id}>
          {parameter.value[selectedLanguage] || parameter.value.en}
        </option>
      ))
  );
};

const SetupFormSelectField = ({
  disabled,
  optionsData,
  selectedLanguage,
  name,
  optionsName,
  emptyOption,
  required,
}) => {
  const { control, trigger } = useFormContext();
  const { t } = useTranslation();
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: {
          value: required,
          message: t('required'),
        },
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl error={!!error} fullWidth>
          <SetupFormSelectFieldInput
            native
            value={value}
            disabled={disabled}
            onChange={(event) => {
              onChange(event.target.value);
              event.target.value === 'Inactive' && trigger();
            }}
            size="small"
          >
            {emptyOption && (
              <option value={emptyOption.value} id={emptyOption.id}>
                {emptyOption.label}
              </option>
            )}
            {createOptionElement(optionsName, optionsData, selectedLanguage)}
          </SetupFormSelectFieldInput>
          <SelectHelperText>{error?.message}</SelectHelperText>
        </FormControl>
      )}
    />
  );
};

export default SetupFormSelectField;
