import {
  SET_ATTRACTIONS_LIST,
  SET_SMALL_ATTRACTIONS_DATA,
  SET_CURRENT_ATTRACTION,
  SET_SELECTED_REGION,
  SET_SELECTED_AREA,
  SET_PAGE_SIZE,
  SET_PAGE_NUMBER,
  SET_TOTAL_COUNT,
  SET_TOTAL_PAGES,
  IS_LOADING_LIST_ATTRACTIONS,
  IS_LOADING_ATTRACTION,
  SET_ADD_ELEMENT,
  SET_STATUS_VALUE,
  SET_ATTRACTIONS_VALUE,
  SET_CATEGORY_VALUE,
  SET_REGION_VALUE,
  SET_AREA_VALUE,
  SET_LOCATION_VALUE,
  SET_ATTRACTION_SEARCH_PARAMS,
  SET_FORM_DATA,
  RESET_FORM_DATA,
} from './attractionsActions';

import {DEFAULT_CATEGORY_INPUT_VALUE, DEFAULT_AUTOCOMPLETE_VALUE} from "../../../pages/attractions/constants"

const initialState = {
  attractionsListData: [],
  attractionsSmallData: [],
  currentAttraction: {},
  selectedRegion: '',
  selectedArea: '',
  pageSize: 1000,
  pageNumber: 0,
  totalCount: 0,
  totalPages: 0,
  isLoadingListAttractions: false,
  isLoadingAttraction: false,
  addElement: false,
  statusValue: '',
  attractionsValue: '',
  categoryValue: '',
  regionValue: '',
  areaValue: '',
  locationValue: '',
  attractionSearchParams: '',
  formData: {
    statusValue: '',
    searchValue: '',
    categoryValue: DEFAULT_CATEGORY_INPUT_VALUE,
    regionValue: DEFAULT_AUTOCOMPLETE_VALUE,
    areaValue: DEFAULT_AUTOCOMPLETE_VALUE,
    locationValue: DEFAULT_AUTOCOMPLETE_VALUE,
    sortField: '',
    sortStatus: '',
  },
};

const attractionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ATTRACTIONS_LIST:
      return {
        ...state,
        attractionsListData: [...action.payload.data],
      };
    case SET_SMALL_ATTRACTIONS_DATA:
      return {
        ...state,
        attractionsSmallData: [...action.payload.data],
      };
    case SET_CURRENT_ATTRACTION:
      return {
        ...state,
        currentAttraction: action.payload.data,
      };
    case SET_SELECTED_REGION:
      return {
        ...state,
        selectedRegion: action.selectedRegion,
      };
    case SET_SELECTED_AREA:
      return {
        ...state,
        selectedArea: action.selectedArea,
      };
    case SET_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.pageSize,
      };
    case SET_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.pageNumber,
      };
    case SET_TOTAL_COUNT:
      return {
        ...state,
        totalCount: action.totalCount,
      };
    case SET_TOTAL_PAGES:
      return {
        ...state,
        totalPages: action.totalPages,
      };
    case IS_LOADING_LIST_ATTRACTIONS:
      return {
        ...state,
        isLoadingListAttractions: action.isLoadingListAttractions,
      };
    case IS_LOADING_ATTRACTION:
      return {
        ...state,
        isLoadingAttraction: action.isLoadingAttraction,
      };
    case SET_ADD_ELEMENT:
      return {
        ...state,
        addElement: action.addElement,
      };

    case SET_STATUS_VALUE:
      return {
        ...state,
        statusValue: action.statusValue,
      };
    case SET_ATTRACTIONS_VALUE:
      return {
        ...state,
        attractionsValue: action.attractionsValue,
      };
    case SET_CATEGORY_VALUE:
      return {
        ...state,
        categoryValue: action.categoryValue,
      };
    case SET_REGION_VALUE:
      return {
        ...state,
        regionValue: action.regionValue,
      };
    case SET_AREA_VALUE:
      return {
        ...state,
        areaValue: action.areaValue,
      };
    case SET_LOCATION_VALUE:
      return {
        ...state,
        locationValue: action.locationValue,
      };
    case SET_ATTRACTION_SEARCH_PARAMS:
      return {
        ...state,
        attractionSearchParams: action.attractionSearchParams,
      };

    case SET_FORM_DATA:
      return {
        ...state,
        formData: action.payload,
      };

    case RESET_FORM_DATA:
      return {
        ...state,
        formData: {},
      };
    default:
      return state;
  }
};

export default attractionsReducer;
