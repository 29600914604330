import {
  SET_USERS_LIST,
  SET_CURRENT_USER,
  SET_USER_SETTINGS,
  IS_USER_LIST_LOADING,
  IS_CURRENT_USER_LOADING,
  IS_SETTINGS_LOADING,
  SET_SELECTED_USER_DATA,
  SET_IS_SELECTED_USER_DATA_LOADING,
} from './userActions';

const initialState = {
  currentUser: null,
  selectedUser: null,
  usersData: [],
  userSettings: [],
  isCurrentUserLoading: false,
  isLoading: false,
  isSettingsLoading: false,
  isSelectedUserDataLoading: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USERS_LIST:
      return {
        ...state,
        usersData: [...action.payload.data],
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: { ...action.payload.data },
      };
    case SET_USER_SETTINGS:
      return {
        ...state,
        userSettings: [...action.payload.data],
      };
    case IS_USER_LIST_LOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    case IS_CURRENT_USER_LOADING:
      return {
        ...state,
        isCurrentUserLoading: action.payload.data,
      };
    case IS_SETTINGS_LOADING:
      return {
        ...state,
        isSettingsLoading: action.payload.data,
      };
    case SET_SELECTED_USER_DATA:
      return {
        ...state,
        selectedUser: action.payload,
      };
    case SET_IS_SELECTED_USER_DATA_LOADING:
      return { ...state, isSelectedUserDataLoading: action.payload };
    default:
      return state;
  }
};

export default userReducer;
