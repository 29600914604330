import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm, FormSection, formValueSelector } from 'redux-form';

import { makeStyles } from '@mui/styles';
import { Box, FormGroup } from '@mui/material';

import { InputForm, SelectInput } from './FormComponents';
import LanguageTabs from './LanguageTabs';
import ConfirmationDialog from '../../../components/confirmationDialog/ConfirmationDialog';
import { CategoryFormImageDrop } from './formFields';

import cl from './CategoryForm.module.css';
import {
  CategoryFormFieldWrapper,
  CategoryFormFieldTitle,
  CategoryFormSelectWrapper,
  CategoryFormHeader,
  CategoryFormControlPanel,
  CategoryFormSubmitButton,
  CategoryFormButton,
  CategoryFormImageDropWrapper,
  CategoryFormCheckbox,
  CategoryFormCheckboxDisabled,
} from './styled';

import { maxLength10, requiredOrder, onlyNumber } from './validate.js';
import { getDestinationAvailability } from './utils';
import {
  NEW_CATEGORY_OBJECT,
  STATUS_ACTIVE,
  ALLOWED_FILE_TYPES,
} from './constants';

const useStyles = makeStyles({
  form: {
    width: '100%',
    '& .MuiTextField-root': {
      width: '650px',
    },
    '& .MuiButton-root': {
      marginRight: '10px',
    },
    '& .MuiOutlinedInput-input': {
      padding: '12.5px 14px;',
    },
  },
  order: {
    height: '35px',
    marginBottom: '50px',
    '& .MuiTextField-root': {
      width: '185px',
    },
    '& .MuiOutlinedInput-input.Mui-disabled': {
      color: '#000',
      WebkitTextFillColor: 'rgba(0, 0, 0, 0.90)',
    },
  },
  status: {
    marginBottom: '30px',
    '& .MuiTextField-root': {
      width: '185px',
    },
    '& .MuiOutlinedInput-input.Mui-disabled': {
      color: '#000',
      WebkitTextFillColor: 'rgba(0, 0, 0, 0.90)',
    },
  },
  input: {
    '&.MuiButton-root': {
      position: 'absolute',
      content: '',
      bottom: '10px',
    },
  },
});

let CategoryForm = (props) => {
  const {
    isEdit,
    setIsEdit,
    setShowAlert,
    handleSubmit,
    updateCategory,
    categoryState,
    selectedLanguage,
    addCategory,
    predefinedState,
    languages,
    error,
    stateDestinations,
    isSave,
    showAlert,
    handleCloseSuccess,
    statusValue,
    onCancel,
  } = props;

  const { t } = useTranslation();
  const classes = useStyles();

  const isStatusActive = statusValue === STATUS_ACTIVE;

  const onEditCategory = () => {
    setIsEdit(true);
  };

  const onDeleteCategory = () => {
    setShowAlert(true);
  };

  const handleClose = () => {
    setShowAlert(false);
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <CategoryFormHeader>
        <div className={cl.title}>
          {updateCategory
            ? categoryState.currentCategory.name[selectedLanguage] ||
              categoryState.currentCategory.name.en
            : `${t('settings_page.category.new_category')}`}
        </div>
        <CategoryFormControlPanel>
          <CategoryFormButton
            onClick={onCancel}
            disabled={addCategory ? false : !isEdit}
            label={t('cancel')}
          />
          {updateCategory && (
            <>
              <CategoryFormButton
                onClick={onEditCategory}
                disabled={isEdit}
                label={t('settings_page.category.edit_button')}
              />
              <CategoryFormButton
                onClick={onDeleteCategory}
                disabled={isSave}
                label={t('settings_page.category.delete_button')}
              />
            </>
          )}
          <CategoryFormSubmitButton
            isLoading={isSave}
            label={t('settings_page.category.save_button')}
            disabled={addCategory || isEdit ? false : true}
          />
        </CategoryFormControlPanel>
      </CategoryFormHeader>
      <Box className={classes.order}>
        <CategoryFormFieldWrapper>
          <CategoryFormFieldTitle>
            {t('settings_page.category.formFieldLabels.order')}
          </CategoryFormFieldTitle>
          <Field
            name="order"
            placeholder={t('settings_page.category.order')}
            component={InputForm}
            validate={
              isStatusActive
                ? [maxLength10, requiredOrder, onlyNumber]
                : [maxLength10, onlyNumber]
            }
            disabled={addCategory ? false : !isEdit}
          />
        </CategoryFormFieldWrapper>
      </Box>
      <Box className={classes.status}>
        <CategoryFormFieldWrapper>
          <CategoryFormFieldTitle>
            {t('settings_page.category.formFieldLabels.status')}
          </CategoryFormFieldTitle>
          <CategoryFormSelectWrapper>
            <Field
              name="status"
              component={SelectInput}
              disabled={addCategory ? false : !isEdit}
            >
              {predefinedState[0].parameters.map((item) => (
                <option key={item.id} value={item.value[selectedLanguage]}>
                  {item.value[selectedLanguage]}
                </option>
              ))}
            </Field>
          </CategoryFormSelectWrapper>
        </CategoryFormFieldWrapper>
      </Box>
      <Box className={cl.tabsBox}>
        <FormSection name="name">
          <LanguageTabs
            addElement={addCategory}
            updateElement={updateCategory}
            isEdit={isEdit}
            currentItem={categoryState.currentCategory}
            text={t('settings_page.category.new_category')}
            languages={languages}
            error={error}
          />
        </FormSection>
      </Box>
      <div className={cl.imageLabel}>
        <CategoryFormFieldTitle>
          {t('settings_page.category.image')}
        </CategoryFormFieldTitle>
      </div>
      <div className={cl.helperText}>
        {t('settings_page.category.image_text')}
      </div>
      <Box style={{ marginBottom: '15px' }}>
        <CategoryFormImageDropWrapper>
          <Field
            name="imageList"
            component={CategoryFormImageDrop}
            filesLimit={1}
            columns={1}
            disabled={addCategory ? false : !isEdit}
          />
        </CategoryFormImageDropWrapper>
      </Box>
      <div className={cl.imageLabel}>
        <CategoryFormFieldTitle>
          {t('settings_page.category.icon')}
        </CategoryFormFieldTitle>
      </div>
      <div className={cl.helperText}>
        {t('settings_page.category.icon_text')}
      </div>
      <Box style={{ marginBottom: '15px' }}>
        <CategoryFormImageDropWrapper>
          <Field
            name="iconList"
            component={CategoryFormImageDrop}
            filesLimit={1}
            columns={1}
            disabled={addCategory ? false : !isEdit}
            allowedFileTypes={ALLOWED_FILE_TYPES}
            contrastBackground
          />
        </CategoryFormImageDropWrapper>
      </Box>
      <div className={cl.radioBox}>
        <FormGroup column>
          <label className={cl.label}>
            {t('settings_page.category.available_for_destination')}
          </label>
          {addCategory
            ? stateDestinations.map((item) => (
                <Field
                  name={item.name}
                  component={CategoryFormCheckbox}
                  label={item.name}
                  key={item.id}
                />
              ))
            : updateCategory && isEdit
            ? stateDestinations.map((item) => (
                <Field
                  name={item.name}
                  component={CategoryFormCheckbox}
                  label={item.name}
                  key={item.id}
                />
              ))
            : stateDestinations.map((item) => (
                <Field
                  name={item.name}
                  component={CategoryFormCheckboxDisabled}
                  label={item.name}
                  disabled
                  isChecked={categoryState.currentCategory.availableForDestination.includes(
                    `${item.id}`,
                    0
                  )}
                  key={item.id}
                />
              ))}
        </FormGroup>
      </div>
      <ConfirmationDialog
        showAlert={showAlert}
        handleClose={handleClose}
        handleCloseSuccess={handleCloseSuccess}
        text={t('settings_page.category.delete_category')}
      />
    </form>
  );
};

const selector = formValueSelector('Category');

const mapStateToProps = (state, ownProps) => {
  const statusValue = selector(state, 'status');

  if (ownProps.addCategory) {
    return { statusValue, initialValues: { ...NEW_CATEGORY_OBJECT } };
  }

  if (ownProps.isEdit && ownProps.categoryState.currentCategory) {
    return {
      statusValue,
      initialValues: {
        order: ownProps.categoryState.currentCategory.order,
        name: { ...ownProps.categoryState.currentCategory.name },
        status: ownProps.categoryState.currentCategory.status,
        imageList: ownProps.categoryState.currentCategory.imageUrl
          ? [ownProps.categoryState.currentCategory.imageUrl]
          : [],
        iconList: ownProps.categoryState.currentCategory.iconUrl
          ? [ownProps.categoryState.currentCategory.iconUrl]
          : [],
        ...getDestinationAvailability(
          ownProps.categoryState.currentCategory.availableForDestination,
          state.settingsReducer.destinationData
        ),
      },
    };
  } else {
    return {
      statusValue,
      initialValues: {
        order: state.categoryReducer.currentCategory.order,
        name: { ...state.categoryReducer.currentCategory.name },
        status: state.categoryReducer.currentCategory.status,
        imageList: state.categoryReducer.currentCategory.imageUrl
          ? [state.categoryReducer.currentCategory.imageUrl]
          : [],
        iconList: state.categoryReducer.currentCategory.iconUrl
          ? [state.categoryReducer.currentCategory.iconUrl]
          : [],
        ...getDestinationAvailability(
          state.categoryReducer.currentCategory.availableForDestination,
          state.settingsReducer.destinationData
        ),
      },
    };
  }
};

export default connect(mapStateToProps)(
  reduxForm({
    form: 'Category',
    enableReinitialize: true,
    destroyOnUnmount: false,
  })(CategoryForm)
);
