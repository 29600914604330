import HomeContainer from '../pages/home/HomeContainer';
import AppVersionPageContainer from '../pages/app-version/AppVersionPageContainer';
import TranslationPageContainer from '../pages/translation/TranslationPageContainer';
import Passes from '../pages/passes/Passes';
import Reports from '../pages/reports/Reports';
import ReviewPageContainer from '../pages/reviews/ReviewPageContainer';
import Terminals from '../pages/terminals/Terminals';
import MyProfilePageContainer from '../pages/my-profile/MyProfilePageContainer';
import NotificationPageContainer from '../pages/notifications/NotificationPageContainer';
import ProductListContainer from '../pages/products/ProductListContainer';
import VisitsListContainer from '../pages/visits/VisitsListContainer';
import GlobalReviewAddFormContainer from '../pages/reviews/forms/GlobalReviewAddFormContainer';
import AddNotificationFormContainer from '../pages/notifications/forms/addNotificationForm/AddNotificationFormContainer';
import TranslationAddFormContainer from '../pages/translation/TranslationAddFormContainer';
import TranslationEditFormContainer from '../pages/translation/TranslationEditFormContainer';
import LogsPage from '../pages/logs/LogsPage';
import CompanyEditFormContainer from '../pages/users/companiesTab/forms/CompanyEditFormContainer';
import UsersTab from '../pages/users/usersTab/UsersTab';
import UserAddFormContainer from '../pages/users/usersTab/forms/UserAddFormContainer';
import UserFormContainer from '../pages/users/usersTab/forms/UserFormContainer';
import AgreementPage from '../pages/agreement/AgreementPage';

import SetupContainer from '../pages/attractions/setup/SetupContainer';
import AttractionContainer from '../pages/attractions/AttractionContainer';
import ContentContainer from '../pages/attractions/content/ContentContainer';
import AvailabilityList from '../pages/attractions/availability/AvailabilityList';
import SeasonContainer from '../pages/attractions/availability/SeasonContainer';
import PricesContainer from '../pages/attractions/prices/PricesContainer';
import { PriceAddFormContainer } from '../pages/attractions/prices/forms';
import DealsContainer from '../pages/attractions/deals/DealsContainer';
import NewDealAddForm from '../pages/attractions/deals/newDealForm/NewDealAddForm';
import DealAddFormContainer from '../pages/attractions/deals/newDealForm/DealAddFormContainer';
import DealGeneralAddFormContainer from '../pages/deals/newDealForm/DealGeneralAddFormContainer';
import DealsGeneralContainer from '../pages/deals/DealsGeneralContainer';
import NotificationTabContainer from '../pages/attractions/notificationsTab/NotificationTabContainer';
import AddNotificationForm from '../pages/attractions/notificationsTab/forms/AddNotificationForm';
import ReviewsTabContainer from '../pages/attractions/reviewsTab/ReviewsTabContainer';
import ReviewAddFormContainer from '../pages/attractions/reviewsTab/forms/ReviewAddFormContainer';
import AttractionVisitsListContainer from '../pages/attractions/visits/AttractionVisitsListContainer';
import AttractionReportPageContainer from '../pages/attractions/reports/AttractionReportPageContainer';
import CompanyContainer from '../pages/attractions/company/CompanyContainer';

import { salesPointsPageRoutes } from './pageInnerRoutes/salesPointPageRoutes';
import { attractionPageRoutes } from './pageInnerRoutes/attractionPageRoutes';
import { settingsPageRoutes } from './pageInnerRoutes/settingsPageRoutes';
import { usersPageRoutes } from './pageInnerRoutes/usersPageRoutes';
import { faqPageRoutes } from './pageInnerRoutes/faqPageRoutes';
import { publishingPageRoutes } from './pageInnerRoutes/publishingPageRoutes';
import AttractionsPageContainer from '../pages/attractions/AttractionsPageContainer';

import { ADMIN, SYS_ADMIN, MANAGER, MANAGER_PRIMARY } from './consts';

export const routes = [
  ...attractionPageRoutes,
  ...salesPointsPageRoutes,
  ...settingsPageRoutes,
  ...usersPageRoutes,
  ...faqPageRoutes,
  ...publishingPageRoutes,
  {
    path: '/',
    element: <HomeContainer />,
    showForRoles: [ADMIN, SYS_ADMIN, MANAGER, MANAGER_PRIMARY],
    children: [
      {
        index: true,
        element: <MyProfilePageContainer />,
        showForRoles: [MANAGER, MANAGER_PRIMARY],
        path: '',
      },
      {
        path: '/atractions',
        element: <AttractionsPageContainer />,
        showForRoles: [MANAGER, MANAGER_PRIMARY],
      },
      {
        path: '/atractions',
        element: <AttractionContainer />,
        showForRoles: [MANAGER, MANAGER_PRIMARY],
        children: [
          {
            element: <SetupContainer />,
            path: 'new',
          },
        ],
      },
      {
        path: '/atractions/:id',
        element: <AttractionContainer />,
        showForRoles: [MANAGER, MANAGER_PRIMARY],
        children: [
          {
            element: <SetupContainer />,
            path: 'setup',
          },
          {
            element: <ContentContainer />,
            path: 'content',
          },
          {
            path: 'availability',
            children: [
              {
                index: true,
                element: <AvailabilityList />,
              },
              {
                element: <SeasonContainer />,
                path: 'new-season',
              },
              {
                element: <SeasonContainer />,
                path: 'edit/:seasonId',
              },
            ],
          },
          {
            path: 'prices',
            children: [
              {
                index: true,
                element: <PricesContainer />,
              },
              {
                element: <PriceAddFormContainer />,
                path: 'new-price',
              },
            ],
          },
          {
            element: <DealsContainer />,
            path: 'deals',
            children: [
              {
                element: <NewDealAddForm />,
                path: 'new',
              },
            ],
          },
          {
            element: <DealAddFormContainer />,
            path: 'new-deal',
          },
          {
            path: 'notifications',
            children: [
              {
                index: true,
                element: <NotificationTabContainer />,
              },
              {
                path: 'new-notification',
                element: <AddNotificationForm />,
              },
            ],
          },
          {
            path: 'reviews',
            children: [
              {
                index: true,
                element: <ReviewsTabContainer />,
              },
              {
                path: 'new-review',
                element: <ReviewAddFormContainer />,
              },
            ],
          },
          {
            element: <AttractionVisitsListContainer />,
            path: 'visits',
          },
          {
            element: <AttractionReportPageContainer />,
            path: 'reports',
          },
          {
            element: <CompanyContainer />,
            path: 'company',
          },
        ],
      },
      {
        path: 'my-company',
        showForRoles: [MANAGER, MANAGER_PRIMARY],
        children: [
          {
            index: true,
            path: ':companyId',
            element: <CompanyEditFormContainer />,
          },
        ]
      },
      {
        path: 'company-users',
        showForRoles: [MANAGER, MANAGER_PRIMARY],
        children: [
          {
            index: true,
            element: <UsersTab />,
          },
          {
            path: 'new-user',
            element: <UserAddFormContainer />,
          },
          {
            path: ':userId',
            element: <UserFormContainer />,
          },
        ]
      },
      {
        element: <AgreementPage />,
        showForRoles: [MANAGER, MANAGER_PRIMARY],
        path: 'agreement',
      },
    ],
  },
  {
    path: '/app-version',
    showForRoles: [ADMIN, SYS_ADMIN],
    element: <AppVersionPageContainer />,
  },
  {
    path: '/translation',
    showForRoles: [ADMIN, SYS_ADMIN],
    children: [
      {
        index: true,
        element: <TranslationPageContainer />,
      },
      {
        path: 'new-translation',
        element: <TranslationAddFormContainer />,
      },
      {
        path: ':id',
        element: <TranslationEditFormContainer />,
      },
    ],
  },
  {
    path: '/passes',
    showForRoles: [ADMIN, SYS_ADMIN],
    element: <Passes />,
  },
  {
    path: '/visits',
    showForRoles: [ADMIN, SYS_ADMIN],
    element: <VisitsListContainer />,
  },
  {
    path: '/products',
    showForRoles: [ADMIN, SYS_ADMIN],
    element: <ProductListContainer />,
  },
  {
    path: '/reports',
    showForRoles: [ADMIN, SYS_ADMIN],
    element: <Reports />,
  },
  {
    path: '/reviews',
    showForRoles: [ADMIN, SYS_ADMIN],
    children: [
      {
        index: true,
        element: <ReviewPageContainer />,
      },
      {
        path: 'new-review',
        element: <GlobalReviewAddFormContainer />,
      },
    ],
  },
  {
    path: '/terminals',
    showForRoles: [ADMIN, SYS_ADMIN],
    element: <Terminals />,
  },
  {
    path: '/my-profile',
    element: <MyProfilePageContainer />,
  },
  {
    path: '/notifications',
    showForRoles: [ADMIN, SYS_ADMIN],
    children: [
      {
        index: true,
        element: <NotificationPageContainer />,
      },
      {
        path: 'new-notification',
        element: <AddNotificationFormContainer />,
      },
    ],
  },
  {
    path: '/logs',
    showForRoles: [ADMIN, SYS_ADMIN],
    element: <LogsPage />,
  },
  {
    path: '/deals',
    showForRoles: [ADMIN, SYS_ADMIN],
    children: [
      {
        index: true,
        element: <DealsGeneralContainer />,
      },
      {
        path: 'new-deal',
        element: <DealGeneralAddFormContainer />,
      }
    ]
  },
];
