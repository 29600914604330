import { compose } from 'redux';
import { connect } from 'react-redux';

import VisitsList from './VisitsList';

import { DEAL_BENEFITS } from '../../consts/consts';
import { getVisitsListThunk } from '../../redux/visits/visitsActions';
import { getSmallAttractionsDataThunk } from '../../redux/attractionsReducers/attractions/attractionsActions';
import { getPredefinedSettingsThunk } from '../../redux/settingsReducers/predefinedReducer';

const mapStateToProps = (state) => {
  const benefitOptions =
    state.predefinedReducer.predefinedData.find(
      (item) => item.name.en === DEAL_BENEFITS
    ) || null;

  return {
    isDataLoading: state.visitsReducer.isDataLoading,
    visitsList: state.visitsReducer.visitsList,
    paginationSettings: state.visitsReducer.paginationSettings,
    attractionsShortData: state.attractionsReducer.attractionsSmallData,
    currentDestinationData: state.appReducer.selectedDestination,
    languages: state.appReducer.selectedDestination.languages,
    benefitOptions,
  };
};

const VisitsListContainer = compose(
  connect(mapStateToProps, {
    getVisitsListThunk,
    getSmallAttractionsDataThunk,
    getPredefinedSettingsThunk,
  })
)(VisitsList);

export default VisitsListContainer;
