import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Table, TableHead, TableContainer, TableBody, TableCell } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { UserTableHeadRow } from './styled';

import { MANAGER_ROLE_LIST, ATTRACTION_MANAGER_PRIMARY } from '../consts';
import { getSmallAttractionsDataThunk } from '../../../../redux/attractionsReducers/attractions/attractionsActions';

const headerLabels = [
  'users.status',
  'users.name',
  'users.email',
  'users.company',
  'users.role',
  'users.created',
  'users.last_login',
];

const headerLabelsManager = [
  'users.status',
  'users.name',
  'users.email',
  'users.phone',
  'users.link_attraction',
  'users.role',
  'users.created',
  'users.last_login',
];

const useStyles = makeStyles({
  userTableHead: {
    '& .MuiTableCell-root:nth-of-type(1)': {
      width: '0%',
    },
    '& .MuiTableCell-root:nth-of-type(2), & .MuiTableCell-root:nth-of-type(3), & .MuiTableCell-root:nth-of-type(4)': {
      width: '15%',
    },
    '& .MuiTableCell-root:nth-of-type(5)': {
      width: '10%',
    },
    '& .MuiTableCell-root:nth-of-type(6), & .MuiTableCell-root:nth-of-type(7)': {
      width: '5%',
    },
  },
  userTableHeadExpanded: {
    '& .MuiTableCell-root:nth-of-type(1)': {
      width: '0%',
    },
    '& .MuiTableCell-root:nth-of-type(2), & .MuiTableCell-root:nth-of-type(3)': {
      width: '11%',
    },
    '& .MuiTableCell-root:nth-of-type(4)': {
      width: '7%',
    },
    '& .MuiTableCell-root:nth-of-type(5)': {
      width: '9%',
    },
    '& .MuiTableCell-root:nth-of-type(6)': {
      width: '10%',
    },
    '& .MuiTableCell-root:nth-of-type(8), & .MuiTableCell-root:nth-of-type(7)': {
      width: '1%',
    },
  }
});

const UsersTable = ({ items, renderItem }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  
  const attractionData = useSelector(state => state.attractionsReducer.attractionsSmallData);  

  const currentUser = useSelector(state => state.userReducer.currentUser);
  const isManagerRoleSelected = MANAGER_ROLE_LIST.includes(currentUser.role);

  useEffect(() => {
    if (items.length > 0) {
      dispatch(getSmallAttractionsDataThunk(items[0]?.company.idDestination));
    }
  }, [dispatch, items]);

  const attractionsMap = attractionData.reduce((acc, attraction) => {
    acc[attraction.id] = attraction.name;
    return acc;
  }, {});

  let updatedItems = items.map(user => ({
    ...user,
    userAttractions: user.userAttractions.map(attraction => ({
      ...attraction,
      name: attractionsMap[attraction.idAttraction] || 'Unknown Attraction'
    }))
  }));

  if (isManagerRoleSelected) {
    const managerUsers = updatedItems.filter(user => user.role === ATTRACTION_MANAGER_PRIMARY);
    updatedItems = managerUsers.concat(updatedItems.filter(user => user.role !== ATTRACTION_MANAGER_PRIMARY));
  }

  return (
    <TableContainer>
      <Table>
        <TableHead className={isManagerRoleSelected? classes.userTableHeadExpanded : classes.userTableHead}>
          <UserTableHeadRow>
            {isManagerRoleSelected ? (
              <>
                {headerLabelsManager.map((item, i) => (
                  <TableCell key={i}>{t(item)}</TableCell>
                ))}
              </>
            ) : 
              <>
                {headerLabels.map((item, i) => (
                  <TableCell key={i}>{t(item)}</TableCell>
                ))}
              </>
            }
          </UserTableHeadRow>
        </TableHead>
        <TableBody>{updatedItems.map(renderItem)}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default UsersTable;
