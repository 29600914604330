import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Typography,
} from '@mui/material';

import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from './constants';
import { getComparator, stableSort, validateTextFieldValue } from './helpers';
import { VisitorsAddButton, VisitorsListWrapper } from './styled';

import VisitorsTableHeader from './visitorsTable/VisitorsTableHeader';
import VisitorsTableRow from './visitorsTable/VisitorsTableRow';
import VisitorSearchForm from './visitorsForm/VisitorSearchForm';

import {
  getVisitorsThunk,
  setPaginationSettings,
} from '../../../redux/usersReducers/visitors/visitorsActions';

const VisitorsList = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [activeRowId, setActiveRowId] = useState(-1);
  const [orderBy, setOrderBy] = useState('id');
  const [orderDirection, setOrderDirection] = useState('desc');

  const TABLE_PAGINATION_OPTIONS = [
    { label: t('all_pagination_option'), value: 1000 },
    { label: '20', value: 20 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ];

  const {
    visitors,
    paginationSettings,
    isDataLoading,
    getProductSettingsThunk,
    productSettings,
    selectedLanguage,
  } = props;

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      status: '',
      searchQuery: '',
    },
  });

  const { watch, setValue, reset } = formMethods;

  const statusValue = watch('status');
  const searchQueryValue = watch('searchQuery');

  const handleChangeSearchValue = (e) => {
    setValue('searchQuery', validateTextFieldValue(e.target.value));
    dispatch(
      setPaginationSettings({
        ...paginationSettings,
        pageNumber: 0,
      })
    );
  };

  const handleExpandRow = (id) => {
    setActiveRowId((currentValue) => (currentValue !== id ? id : -1));
  };

  const handlePaginationSettings = (pageSize, pageNumber) => {
    dispatch(
      setPaginationSettings({
        ...paginationSettings,
        pageSize,
        pageNumber,
      })
    );
  };

  const onRowsPerPage = (e) =>
    handlePaginationSettings(parseInt(e.target.value, 10), DEFAULT_PAGE_NUMBER);

  const onChangePage = (_, newPageNumber) =>
    handlePaginationSettings(paginationSettings.pageSize, newPageNumber);

  const handleAddButtonClick = () => {
    navigate('/users/new-visitor');
    handlePaginationSettings(DEFAULT_PAGE_SIZE, DEFAULT_PAGE_NUMBER);
  };

  const handleSort = (_, property) => {
    const isAsc = orderBy === property && orderDirection === 'asc';
    setOrderDirection(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    dispatch(
      getVisitorsThunk(
        paginationSettings.pageNumber,
        paginationSettings.pageSize,
        statusValue,
        searchQueryValue
      )
    );
  }, [
    statusValue,
    searchQueryValue,
    dispatch,
    paginationSettings.pageNumber,
    paginationSettings.pageSize,
    getProductSettingsThunk,
  ]);

  useEffect(() => {
    getProductSettingsThunk();
  }, [getProductSettingsThunk]);

  return (
    <VisitorsListWrapper>
      <Box>
        <Typography fontSize="20px" fontWeight={500} textTransform="uppercase">
          {t('visitors.headline')}
        </Typography>
      </Box>
      <Box mt="10px" mb="10px">
        <VisitorsAddButton variant="contained" onClick={handleAddButtonClick}>
          {t('visitors.addVisitorButton')}
        </VisitorsAddButton>
      </Box>

      <FormProvider {...formMethods}>
        <VisitorSearchForm
          paginationSettings={paginationSettings}
          handleChangeSearchValue={handleChangeSearchValue}
        />
      </FormProvider>

      {isDataLoading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer>
          <TablePagination
            rowsPerPageOptions={TABLE_PAGINATION_OPTIONS}
            onPageChange={onChangePage}
            rowsPerPage={paginationSettings.pageSize}
            page={paginationSettings.pageNumber}
            component="div"
            count={paginationSettings.totalCount}
            onRowsPerPageChange={onRowsPerPage}
            labelRowsPerPage={t('attractions.deals.rows_per_page')}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}–${to} ${t('attractions.deals.of')} ${
                count !== -1 ? count : `more than ${to}`
              }`
            }
          />
          <Table>
            <VisitorsTableHeader
              orderBy={orderBy}
              order={orderDirection}
              onSort={handleSort}
            />
            <TableBody>
              {visitors &&
                stableSort(
                  visitors,
                  getComparator(orderDirection, orderBy)
                ).map((visitorData, idx) => (
                  <VisitorsTableRow
                    key={idx}
                    visitorData={visitorData}
                    statusFilterValue={statusValue}
                    searchQuery={searchQueryValue}
                    isExpanded={activeRowId === idx}
                    onExpand={() => handleExpandRow(idx)}
                    paginationSettings={paginationSettings}
                    resetActiveRow={() => setActiveRowId(-1)}
                    productSettings={productSettings}
                    selectedLanguage={selectedLanguage}
                    handleResetFilters={reset}
                  />
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={TABLE_PAGINATION_OPTIONS}
            onPageChange={onChangePage}
            rowsPerPage={paginationSettings.pageSize}
            page={paginationSettings.pageNumber}
            component="div"
            count={paginationSettings.totalCount}
            onRowsPerPageChange={onRowsPerPage}
            labelRowsPerPage={t('attractions.deals.rows_per_page')}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}–${to} ${t('attractions.deals.of')} ${
                count !== -1 ? count : `more than ${to}`
              }`
            }
          />
        </TableContainer>
      )}
    </VisitorsListWrapper>
  );
};

export default VisitorsList;
