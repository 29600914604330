import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { TablePagination, TableContainer, Table, Box, CircularProgress } from '@mui/material';

import AttractionTableDataRow from './attractionsTable/AttractionTableDataRow';
import AttractionTableHeader from './attractionsTable/AttractionTableHeader';

import {
  setPageNumber,
  setPageSize,
} from '../../redux/attractionsReducers/attractions/attractionsActions';
import {
  TABLE_PAGINATION_OPTIONS,
  TYPE_AREA,
  TYPE_LOCATION,
  TYPE_REGION,
} from './constants';
import {useTranslation} from "react-i18next";

const AttractionsList = (props) => {
  const {
    appState,
    categoryState,
    settingsState,
    attractionsState,
    pageNumber,
    pageSize,
    sortBy,
    sortField,
    sortStatus,
    handleAttractionClick,
    isLoading,
    setIsLoading,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [createRows, setCreateRows] = useState(null);

  const destinationDefaultLanguage = appState.selectedDestination.languages[0];

  const TABLE_PAGINATION_OPTIONS = [
    { label: t('all_pagination_option'), value: 1000 },
    { label: '10', value: 10 },
    { label: '25', value: 25 },
    { label: '50', value: 50 },
  ];

  const isRegionEnabledInDestination =
    appState.selectedDestination.enabledLocationTypes?.includes(TYPE_REGION);
  const isAreaEnabledInDestination =
    appState.selectedDestination.enabledLocationTypes?.includes(TYPE_AREA);
  const isLocationEnabledInDestination =
    appState.selectedDestination.enabledLocationTypes?.includes(TYPE_LOCATION);

  const dictionaryCategoryData = Object.assign(
    {},
    ...categoryState.categoryData.map((item) => ({
      [item.id]: item.name[destinationDefaultLanguage],
    }))
  );

  const dictionaryRegionData = Object.assign(
    {},
    ...settingsState.dictionaryRegionData.map((item) => ({
      [item.id]: item.title[destinationDefaultLanguage],
    }))
  );

  const dictionaryAreaData = Object.assign(
    {},
    ...settingsState.dictionaryAreaData.map((item) => ({
      [item.id]: item.title[destinationDefaultLanguage],
    }))
  );

  const dictionaryLocationData = Object.assign(
    {},
    ...settingsState.dictionaryLocationData.map((item) => ({
      [item.id]: item.title[destinationDefaultLanguage],
    }))
  );

  const createData = (
    status,
    name,
    title,
    categories,
    region,
    regionOrder,
    area,
    areaOrder,
    location,
    locationOrder,
    id
  ) => {
    return {
      status,
      name,
      title,
      categories,
      region,
      regionOrder,
      area,
      areaOrder,
      location,
      locationOrder,
      id,
    };
  };

  useEffect(() => {
    setIsLoading(true);
    const loadedRows = attractionsState.attractionsListData.map((item) =>
    createData(
      item.status,
      item.name,
      item.title[destinationDefaultLanguage] || 'No title',
      dictionaryCategoryData[item.category] || 'No category',
      dictionaryRegionData[item.regionId] || 'No region',
      item.regionOrder || 0,
      dictionaryAreaData[item.areaId] || 'No area',
      item.areaOrder || 0,
      dictionaryLocationData[item.locationId] || 'No location',
      item.locationOrder || 0,
      item.id
    ));

    setCreateRows(loadedRows);
    setIsLoading(false);

  }, [attractionsState.attractionsListData]);

  const handleChangePageNumber = (_, newPage) => {
    dispatch(setPageNumber(newPage));
  };

  const handleChangePageSize = (event) => {
    dispatch(setPageSize(event.target.value));
  };

  return (
    <Box sx={{ width: '100%' }}>
      <TablePagination
        rowsPerPageOptions={TABLE_PAGINATION_OPTIONS}
        onRowsPerPageChange={handleChangePageSize}
        component="div"
        count={attractionsState.totalCount}
        page={pageNumber}
        onPageChange={handleChangePageNumber}
        rowsPerPage={pageSize}
        labelRowsPerPage={t('attractions.deals.rows_per_page')}
      />
      {
        isLoading
        ? <Box
            component="div"
            sx={{ width: '100%', position: 'relative'}}
          >
            <CircularProgress
              sx={{ position: 'absolute', right: '50%', top: '200px' }}
            />
          </Box>
        : 
        <>
          <TableContainer>
            <Table sx={{ minWidth: 650 }}>
              <AttractionTableHeader
                onSort={sortBy}
                value={sortField}
                status={sortStatus}
                showRegionColumn={isRegionEnabledInDestination}
                showAreaColumn={isAreaEnabledInDestination}
                showLocationColumn={isLocationEnabledInDestination}
              />
              {createRows?.map((item, index) => (
                <AttractionTableDataRow
                  key={index}
                  onClickRow={handleAttractionClick}
                  attractionRow={item}
                  showRegionColumn={isRegionEnabledInDestination}
                  showAreaColumn={isAreaEnabledInDestination}
                  showLocationColumn={isLocationEnabledInDestination}
                />
              ))}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={TABLE_PAGINATION_OPTIONS}
            onRowsPerPageChange={handleChangePageSize}
            component="div"
            count={attractionsState.totalCount}
            page={pageNumber}
            onPageChange={handleChangePageNumber}
            rowsPerPage={pageSize}
            labelRowsPerPage={t('attractions.deals.rows_per_page')}
          />
        </>
    }
    </Box>
  );
};

export default AttractionsList;
