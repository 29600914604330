import {useEffect, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import { useTranslation } from 'react-i18next';

import VisitorEditForm from './VisitorEditForm';

import useAPIError from '../../../../apIErrorProvider/useAPIError';
import {
  createUserThunk,
  getVisitorsThunk,
  updateVisitorThunk
} from '../../../../redux/usersReducers/visitors/visitorsActions';
import { getUserSettingsThunk } from '../../../../redux/usersReducers/users/userActions';
import { getProductsThunk } from '../../../../redux/productsReducer/productActions';

import {
    activateVisitor,
    changeVisitorPassword, createUser,
    deleteVisitor,
} from '../../../../api/visitorsApi';
import {getCompaniesListThunk} from "../../../../redux/usersReducers/company/companyActions";

const VisitorEditFormContainer = ({
  destinationId,
  userSettings,
  products,
  visitorData,
  updateVisitorThunk,
  isInEditMode,
  handleCancelChanges,
  handleChangeEditMode,
  resetActiveRow,
  languageList, 
  paginationSettings,
  handleResetFilters,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { addError } = useAPIError();

  const [showSaveAnimation, setShowSaveAnimation] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isChangePasswordPopupOpen, setIsChangePasswordPopupOpen] = useState(false);
  const [isVisitorActivationPopupOpen, setIsVisitorActivationPopupOpen] = useState(false);
  const [isUserCreationPopupOpen, setIsUserCreationPopupOpen] = useState(false);

  const { id, name, status, isLinkedUserExist } = visitorData;
  
  const handleChangePassword = () => {
    changeVisitorPassword(id)
      .then((response) => {
        resetActiveRow();
        handleResetFilters();
        response.status === 200 &&
          addError(t('visitors.change_visitor_password_success'), 'Success');
      })
      .catch((error) => {
        addError(t('visitors.change_visitor_password_error'), 'Error', error);
      });
    setIsChangePasswordPopupOpen(false);
  };
  
  const handleActivateVisitor = () => {
    const productId = status === 'Registered' ?
        products.find(product => product.type === 'FirstTimeRegistration').id
        : products.find(product => product.type === 'Renewal').id
    activateVisitor(id,productId)
        .then(() => {
          resetActiveRow();
          handleResetFilters();
          addError(t('visitors.success_visitor_activate'), 'Success');
          dispatch(getVisitorsThunk(paginationSettings.pageNumber, paginationSettings.pageSize, '', ''))
        })
        .catch((error) => {
          addError(t('visitors.error_visitor_activate'), 'Error', error);
        });
    setIsVisitorActivationPopupOpen(false);
  };

  const handleUserCreation = (companyId, role) => {
    dispatch(createUserThunk(id, destinationId, companyId, role))
        .then(() => {
          resetActiveRow();
          handleResetFilters();
          addError(t('visitors.success_user_created'), 'Success');
          dispatch(getVisitorsThunk(paginationSettings.pageNumber, paginationSettings.pageSize, '', ''))
        })
        .catch((error) => {
          addError(t('visitors.error_user_creation'), 'Error', error);
        });
    setIsUserCreationPopupOpen(false);
  };

  const handleDeleteVisitor = () => {
    deleteVisitor(id)
      .then(() => {
        resetActiveRow();
        handleResetFilters();
        addError(t('visitors.delete_visitor_success'), 'Success');
        dispatch(getVisitorsThunk(paginationSettings.pageNumber, paginationSettings.pageSize, '', ''))
      })
      .catch((error) => {
        addError(t('visitors.delete_visitor_error'), 'Error', error);
      });
  };

  const onSubmit = (data) => {
    const submitData = {
      id,
      status: data.status,
      name: data.name.trim(),
      email: data.email.trim(),
      address: data.address,
      language: data.language,
      validUntil: data.validUntil,
    };
    
    setShowSaveAnimation(true);
    updateVisitorThunk(id, submitData)
      .then(() => {
        resetActiveRow();
        handleResetFilters();
        setShowSaveAnimation(false);
        dispatch(getVisitorsThunk(paginationSettings.pageNumber, paginationSettings.pageSize, '', ''))
        addError(t('visitors.success_update_visitor'), 'Success');
      })
      .catch((error) => {
        setShowSaveAnimation(false);

        addError(t('users.error_add_visitor'), 'Error', error);
      });
  };
  return (
    <VisitorEditForm
      onSubmit={onSubmit}
      isLoading={showSaveAnimation}
      userSettings={userSettings}
      isInEditMode={isInEditMode}
      visitorId={id}
      visitorName={name}
      visitorStatus={status}
      isLinkedUserExist={isLinkedUserExist}
      handleChangeEditMode={handleChangeEditMode}
      handleCancelChanges={handleCancelChanges}
      handleActivateVisitor={handleActivateVisitor}
      handleUserCreation={handleUserCreation}
      handleClosePopup={() => setIsPopupOpen(false)}
      handleOpenPopup={() => setIsPopupOpen(true)}
      handleCloseChangePasswordPopup={() => setIsChangePasswordPopupOpen(false)}
      handleOpenChangePasswordPopup={() => setIsChangePasswordPopupOpen(true)}
      handleCloseVisitorActivationPopup={() => setIsVisitorActivationPopupOpen(false)}
      handleOpenVisitorActivationPopup={() => setIsVisitorActivationPopupOpen(true)}
      handleCloseUserCreationPopup={() => setIsUserCreationPopupOpen(false)}
      handleOpenUserCreationPopup={() => setIsUserCreationPopupOpen(true)}
      onPopupConfirm={handleDeleteVisitor}
      isPopupOpen={isPopupOpen}
      isChangePasswordPopupOpen={isChangePasswordPopupOpen}
      isVisitorActivationPopupOpen={isVisitorActivationPopupOpen}
      isUserCreationPopupOpen={isUserCreationPopupOpen}
      onChangePasswordPopupConfirm={handleChangePassword}
      onVisitorActivationPopupConfirm={handleActivateVisitor}
      languageList={languageList}
    />
  );
};
const mapStateToProps = (state) => {
  return {
    destinationId: state.appReducer.selectedDestination.id,
    userSettings: state.userReducer.userSettings,
    products: state.productsReducer.products,
    paginationSettings: state.visitorsReducer.paginationSettings,
  };
};

export default connect(mapStateToProps, {
  updateVisitorThunk,
  getVisitorsThunk,
})(VisitorEditFormContainer);
