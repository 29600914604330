import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CircularProgress } from '@mui/material';

import useAPIError from '../../../../apIErrorProvider/useAPIError';
import UserForm from './UserForm';

import {
  addUserThunk,
  getUserSettingsThunk,
} from '../../../../redux/usersReducers/users/userActions';
import {
  getCompaniesListThunk,
  getAllCompaniesShortDataThunk,
  getAttractionListOfCompaniesThunk,
} from '../../../../redux/usersReducers/company/companyActions';
import { getCategoryThunk } from '../../../../redux/settingsReducers/categoryReducer';
import { replaceFieldName } from '../helpers';
import { isAdminRole } from '../UserRoles';

import { USER_STATUS, USER_ROLES, MANAGER_ROLE_LIST } from '../../../../consts/consts';
import {
  ID_ATTRACTION,
  ID_REGION,
  ID_CATEGORY,
  DEFAULT_LANGUAGE,
  SALES_POINT_ID,
} from '../consts';

const UserAddFormContainer = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { addError } = useAPIError();
  const navigate = useNavigate();

  const [showSaveAnimation, setShowSaveAnimation] = useState(false);

  const selectedDestinationId = useSelector(
    (state) => state.appReducer.selectedDestination.id
  );
  
  useEffect(() => {
    dispatch(getAttractionListOfCompaniesThunk(selectedDestinationId))
  }, [])

  const defaultCompany = useSelector(
    (state) => state.companyReducer.defaultCompany
  );

  const currentUserRole = useSelector((state) => state.authReducer.role);
  const currentUser = useSelector((state) => state.userReducer.currentUser);
  const isManagerRoleSelected = MANAGER_ROLE_LIST.includes(currentUser.role);

  const isSelectedCompanyAttractionsLoaded = useSelector(
    (state) => state.companyReducer.isAttractionListOfCompaniesLoaded
  );

  const userStatuses = useSelector((state) =>
    state.userReducer.userSettings.find((item) => item.name.en === USER_STATUS)
  );
  const userRoles = useSelector((state) =>
    state.userReducer.userSettings.find((item) => item.name.en === USER_ROLES)
  );
  const categoryList = useSelector(
    (state) => state.categoryReducer.categoryData
  );
  const regionList = useSelector(
    (state) => state.settingsReducer.dictionaryRegionData
  );
  const { allCompaniesShortDataList } = useSelector(
    (state) => state.companyReducer
  );
  const selectedLanguage = useSelector(
    (state) => state.appReducer.selectedLanguage
  );

  const attractionListOfCompanies = useSelector(
    (state) => state.companyReducer.attractionListOfCompanies
  );

  const pointList = useSelector((state) => [
    ...state.salesPointsReducer.pointShortDataList,
  ]);

  const sortedPoints = pointList.sort(function (a, b) {
    const textA = a.name.toUpperCase();
    const textB = b.name.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });

  const attractionList = useSelector((state) => [
    ...state.attractionsReducer.attractionsSmallData,
  ]);

  const sortedAttractions = attractionList.sort(function (a, b) {
    const textA = a.name.toUpperCase();
    const textB = b.name.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });

  const attractionsAndPointsLinkedToCompaniesWithDefaultCompany = [
    ...attractionListOfCompanies,
    {
      id: defaultCompany.id,
      attractions: sortedAttractions,
      salesPoints: sortedPoints,
    },
  ];

  const handleSubmitAddUserForm = (formData) => {
    setShowSaveAnimation(true);
    let newUser;
    if (isAdminRole(formData.role)) {
      newUser = {
        name: formData.name ? formData.name.trim() : '',
        position: formData.position ? formData.position.trim() : '',
        email: formData.email ? formData.email.trim() : '',
        phone: formData.phone ? formData.phone.trim() : '',
        message: formData.message,
        role: formData.role,
        status: formData.status,
        companyId: defaultCompany.id,
        isAllAttractions: false,
        isAllSalesPoints: false,
        regions: [],
        attractionCategories: [],
        attractions: [],
        salesPoints: [],
        destination: selectedDestinationId,
        language: DEFAULT_LANGUAGE,
      };
    } else {
      newUser = {
        name: formData.name ? formData.name.trim() : '',
        position: formData.position ? formData.position.trim() : '',
        email: formData.email ? formData.email.trim() : '',
        phone: formData.phone ? formData.phone.trim() : '',
        message: formData.message,
        role: formData.role,
        status: formData.status,
        companyId: formData.company.id,
        isAllAttractions: formData.isAllAttractionsAndPoints || false,
        isAllSalesPoints: formData.isAllAttractionsAndPoints || false,
        regions: formData.regions
          ? replaceFieldName(formData.regions, 'id', ID_REGION)
          : [],
        attractionCategories: formData.categories
          ? replaceFieldName(formData.categories, 'id', ID_CATEGORY)
          : [],
        attractions: formData.attractions
          ? replaceFieldName(formData.attractions, 'id', ID_ATTRACTION)
          : [],
        salesPoints: formData.salesPoints
          ? replaceFieldName(formData.salesPoints, 'id', SALES_POINT_ID)
          : [],
        destination: selectedDestinationId,
        language: DEFAULT_LANGUAGE,
      };
    }
    dispatch(addUserThunk(newUser))
      .then(() => {
        setShowSaveAnimation(false);
        navigate(isManagerRoleSelected ? '/company-users' : '/users');
      })
      .then(() => addError(`${t('users.success_add_user')}`, 'Success'))
      .catch((error) => {
        addError(`${t('users.error_add_user')}`, 'Error', error);
        setShowSaveAnimation(false);
      });
  };

  const handleAddUserFormCancel = () => {
    setShowSaveAnimation(false);
    navigate(isManagerRoleSelected ? '/company-users' : '/users');
  };

  useEffect(() => {
    dispatch(getUserSettingsThunk());
    dispatch(getCategoryThunk());
    dispatch(getAllCompaniesShortDataThunk());

    if (selectedDestinationId) {
      dispatch(getCompaniesListThunk(selectedDestinationId));
    }
  }, [dispatch, selectedDestinationId]);  

  const isFormReady =
    !!allCompaniesShortDataList.length &&
    userRoles &&
    userStatuses &&
    selectedLanguage &&
    (!isManagerRoleSelected ? defaultCompany?.id : true);

  return (
    <>
      {isFormReady ? (
        <UserForm
          onSubmit={handleSubmitAddUserForm}
          onCancel={handleAddUserFormCancel}
          isInAddMode
          isInEditMode
          showSaveAnimation={showSaveAnimation}
          isSelectedCompanyAttractionsLoaded={
            isSelectedCompanyAttractionsLoaded
          }
          currentUserRole={currentUserRole}
          roles={userRoles}
          statuses={userStatuses}
          selectedLanguage={selectedLanguage}
          pointList={pointList}
          attractionsAndSalesPointsLinkedToCompanies={
            attractionsAndPointsLinkedToCompaniesWithDefaultCompany
          }
          categoryList={categoryList}
          regionList={regionList}
          companyList={allCompaniesShortDataList}
          defaultUserCompany={defaultCompany}
          selectedDestinationId={selectedDestinationId}
        />
      ) : (
        <CircularProgress />
      )}
    </>
  );
};

export default UserAddFormContainer;
