import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { CircularProgress, Typography, Box } from '@mui/material';

import {
  UsersTabWrapper,
  UsersFilterWrapper,
  LoaderWrapper,
  UsersAddButton,
} from './styled';

import UserFilterForm from './forms/UserFilterForm';
import UsersTable from './components/UsersTable';
import UsersTableRow from './components/UsersTableRow';

import {
  getUsersListThunk,
  getUserSettingsThunk,
} from '../../../redux/usersReducers/users/userActions';
import { getAllCompaniesShortDataThunk } from '../../../redux/usersReducers/company/companyActions';
import { getCategoryThunk } from '../../../redux/settingsReducers/categoryReducer';

import { USER_ROLES, USER_STATUS, MANAGER_ROLE_LIST_APP } from '../../../consts/consts';

const UsersTab = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [status, setStatus] = useState('');
  const [nameOrEmail, setNameOrEmail] = useState('');
  const [company, setCompany] = useState(null);
  const [role, setRole] = useState('');

  const availableCompanies = useSelector(
    (state) => state.companyReducer.allCompaniesShortDataList
  );

  const userStatuses = useSelector((state) =>
    state.userReducer.userSettings?.find((item) => item.name.en === USER_STATUS)
  );

  const userRoles = useSelector((state) =>
    state.userReducer.userSettings?.find((item) => item.name.en === USER_ROLES)
  );

  const { usersData, isLoading, isSettingsLoading } = useSelector(
    (state) => state.userReducer
  );

  const selectedDestinationId = useSelector(
    (state) => state.appReducer.selectedDestination.id
  );
  const selectedLanguage = useSelector(
    (state) => state.appReducer.selectedLanguage
  );

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleNameOrEmailChange = (value) => {
    setNameOrEmail(value);
  };

  const handleCompanyChange = (_, newValue) => {
    setCompany(newValue);
  };

  const handleRoleChange = (e) => {
    setRole(e.target.value);
  };

  const handleAddUser = () => {
    navigate('new-user');
  };

  const handleExpandTableRow = (panelId) => {
    return () => {
      navigate(panelId.toString());
    };
  };

  const handleResetFilters = () => {
    setStatus('');
    setNameOrEmail('');
    setCompany(null);
    setRole('');
  };

  useEffect(() => {
    dispatch(
      getUsersListThunk({
        status: status || null,
        nameOrEmail: nameOrEmail || null,
        idCompany: company?.id || null,
        role: role || null,
      })
    );
  }, [status, nameOrEmail, company?.id, role, dispatch]);

  useEffect(() => {
    dispatch(getUsersListThunk({}));
    dispatch(getAllCompaniesShortDataThunk());
    dispatch(getCategoryThunk());
    dispatch(getUserSettingsThunk());
  }, [dispatch, selectedDestinationId]);

  const currentUserRole = useSelector((state) => state.authReducer.role);
  const isManagerRoleSelected = MANAGER_ROLE_LIST_APP.includes(currentUserRole);

  return (
    <UsersTabWrapper>
      <Typography fontSize="20px" fontWeight={500} textTransform="uppercase">
        {t('users.list_of_users')}
      </Typography>
      <Box mt="10px" mb="10px">
        <UsersAddButton
          variant="contained"
          onClick={handleAddUser}
          disabled={!selectedDestinationId}
        >
          {t('users.add_new_user')}
        </UsersAddButton>
      </Box>
      {!isManagerRoleSelected &&
        <>
          <UsersFilterWrapper>
            {!isSettingsLoading && (
              <UserFilterForm
                availableStatus={userStatuses}
                availableCompanies={availableCompanies}
                availableRoles={userRoles}
                selectedLanguage={selectedLanguage}
                onStatusChange={handleStatusChange}
                onNameOrEmailChange={handleNameOrEmailChange}
                onCompanyChange={handleCompanyChange}
                onRoleChange={handleRoleChange}
                status={status}
                nameOrEmail={nameOrEmail}
                company={company}
                role={role}
                handleResetFilters={handleResetFilters}
              />
            )}
          </UsersFilterWrapper>
        </>
      }
      {!isLoading ? (
        <UsersTable
          items={usersData}
          renderItem={(item, i) => {
            return (
              <UsersTableRow
                user={item}
                onClick={handleExpandTableRow(item.id)}
                key={i}
                selectedLanguage={selectedLanguage}
              />
            );
          }}
        />
      ) : (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      )}
    </UsersTabWrapper>
  );
};

export default UsersTab;
