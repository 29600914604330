import {
  DEFAULT_EMAIL_FIELD_MAX_LENGTH,
  DEFAULT_TEXT_FIELD_MAX_LENGTH,
} from '../../../consts/validation';

export const EMAIL_MAX_LENGTH = DEFAULT_EMAIL_FIELD_MAX_LENGTH;
export const ADDRESS_MAX_LENGTH = DEFAULT_TEXT_FIELD_MAX_LENGTH;
export const VISITOR_NAME_MAX_LENGTH = DEFAULT_TEXT_FIELD_MAX_LENGTH;
export const NEW_VISITOR_STATUS = 'Active';

export const EMAIL_PATTERN = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/i
);

export const VISITOR_DELETED_STATUS = 'Deleted';

export const STATUS_FILTER_OPTIONS = [
  {
    value: '',
    label: 'visitors.statuses.default',
  },
  {
    value: 'Active',
    label: 'visitors.statuses.active',
  },
  {
    value: 'Registered',
    label: 'visitors.statuses.registered',
  },
  {
    value: 'Expired',
    label: 'visitors.statuses.expired',
  },
  {
    value: 'Expiring',
    label: 'visitors.statuses.expiring',
  },
  {
    value: 'Inactive',
    label: 'visitors.statuses.inactive',
  },
];

export const tableHeadCells = [
  {
    id: 'status',
    label: 'visitors.tableHeader.status',
    sortable: false,
  },
  {
    id: 'id',
    label: 'visitors.tableHeader.number',
    sortable: false,
  },
  {
    id: 'name',
    label: 'visitors.tableHeader.name',
    sortable: false,
  },
  { id: 'email', label: 'visitors.tableHeader.email', sortable: false },
  { id: 'created', label: 'visitors.tableHeader.created', sortable: false },
  {
    id: 'validFrom',
    label: 'visitors.tableHeader.valid_from',
    sortable: false,
  },
  {
    id: 'validUntil',
    label: 'visitors.tableHeader.valid_until',
    sortable: false,
  },
  { id: 'view', label: 'visitors.tableHeader.view', sortable: false },
];


export const DEFAULT_PAGE_SIZE = 1000;
export const DEFAULT_PAGE_NUMBER = 0;
export const DEFAULT_VISITOR_LANGUAGE = 'en';
export const ACTIVE_COMPANY_STATUS = 'Active';
export const PRODUCT_TYPES = 'Product types';
export const USER_ROLE_SETTINGS_ENGLISH_NAME = 'User Roles';
export const VISITOR_LANGUAGE = 'Visitor Language';
export const USER_ADMIN_ROLE = 'Administrator'
export const USER_SYSTEM_MANAGER_ROLE = 'SystemManager'
export const VISITOR_REGISTERED_STATUS = 'Registered'
export const VISITOR_EXPIRED_STATUS = 'Registered'
export const TABLE_PAGINATION_OPTIONS = [
  { label: 'ALL', value: 1000 },
  { label: '20', value: 20 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];
