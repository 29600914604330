import { DEFAULT_TEXT_FIELD_MAX_LENGTH } from '../../../consts/validation';

export const defaultStatus = 'Inactive';
export const defaultNumberOfUses = 1;
export const defaultBenefit = 'Percentage';
export const ACTIVE = 'Active';
export const SPECIFICATION = 'Specification';
export const NOT_LIMITED = 'NotLimited';
export const EXACT = 'Exact';
export const DISCOUNT = 'Discount';
export const USES_NUMBER_SELECT_OPTIONS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export const DEAL_TEXT_FIELD_MAX_LENGTH = DEFAULT_TEXT_FIELD_MAX_LENGTH;
export const EXPIRED = 'Expired';
export const DEFAULT_PAGE_NUMBER = 0;
export const DEFAULT_PAGE_SIZE = 1000;

export const ADMIN_ROLE_LIST = ['Administrator', 'SystemManager'];
export const MANAGER_ROLE_LIST = ['AttractionManager', 'AttractionManagerPrimary'];