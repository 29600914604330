export const getAvailableCategoriesForDestination = (
  allCategories,
  destinationId
) => {
  if (!destinationId) return [];

  const availableForDestinationCategories = allCategories.reduce(
    (acc, currentCategory) => {
      if (currentCategory.availableForDestination.includes(destinationId)) {
        acc.push({
          id: currentCategory.id,
          name: currentCategory.name,
        });
      }

      return acc;
    },
    []
  );

  return availableForDestinationCategories;
};

export const getFormatedItemsByDestinationId = (allRegions, destinationId) => {
  if (!destinationId) return [];

  const regionsIncludedInDestination = allRegions.reduce(
    (acc, currentRegion) => {
      if (currentRegion.destinationId === destinationId) {
        acc.push({
          id: currentRegion.id,
          name: currentRegion.title,
        });
      }

      return acc;
    },
    []
  );

  return regionsIncludedInDestination;
};
