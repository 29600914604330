import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { reset } from 'redux-form';

import NotificationForm from './NotificationForm';

const NotificationFormContainer = (props) => {
  const {
    languages,
    isAddNotification,
    currentItem,
    statuses,
    onDeleteNotification,
    currentAttractionId,
    selectedLanguage,
    currentAttractionName,
    onFormSubmit,
  } = props;

  const dispatch = useDispatch();

  const [isFormEdit, setIsFormEdit] = useState(false);

  const handleFormSave = (formData) => {
    setIsFormEdit(false);
    onFormSubmit(formData);
  };

  const handleFormEdit = () => {
    setIsFormEdit(true);
  };

  const handleFormCancel = () => {
    setIsFormEdit(false);
    dispatch(reset('NotificationForm'));
  };

  return (
    <NotificationForm
      onSubmit={handleFormSave}
      languages={languages}
      isAddingItem={isAddNotification}
      setIsFormEdit={setIsFormEdit}
      isFormEdit={isFormEdit}
      currentItem={currentItem}
      availableStatuses={statuses}
      onDeleteNotification={onDeleteNotification}
      currentAttractionId={currentAttractionId}
      selectedLanguage={selectedLanguage}
      attractionName={currentAttractionName}
      onFormEdit={handleFormEdit}
      onFormCancel={handleFormCancel}
    />
  );
};

export default NotificationFormContainer;
